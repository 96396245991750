import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BusinessService} from "../../../business.service";
import {
  Invoice, InvoiceItem, MultipleAppts,
  preference,
  RecurringAppointment
} from "../../../common-classes/app-objects.model";
import {NgForm} from "@angular/forms";
import {PackageSold} from "../../../store/packages/packages.model";
import {WaitlistService} from "../../../waitlist/waitlist.service";
import {WaitList} from '../../../../models/WaitList.model';
import {Appointment} from '../../../../models/Appointment.model';

export class CancelBookingObjModalData {
  appt: Appointment;
  preference: preference;
  waitlist: WaitList;
  repeatingAppt: RecurringAppointment;
  cancelType: string;
}

@Component({
  selector: 'app-cancel-appointment',
  templateUrl: './cancel-appointment.component.html'
})
export class CancelAppointmentComponent implements OnInit {
  appt: Appointment;
  repeatingAppt: RecurringAppointment;
  waitlist: WaitList;
  preference: preference;
  cancelReasonType: string = 'text';
  cancelReasonListArray: string[] = [];
  cancelErrorMsg: string;
  cancelFailed: boolean = false;
  cancelSuccess: boolean = false;
  cancelNoReasonEnteredErrorMsg: string;
  noCancelReasonEntered: boolean = false;
  cancelling: boolean = false;
  cancelType: string = 'APPOINTMENT';
  cancelModalTitle: string;
  cancelModalDescription: string;
  cancelReason: string;
  constructor(@Inject(MAT_DIALOG_DATA) private passedData: CancelBookingObjModalData, public dialogRef: MatDialogRef<CancelAppointmentComponent>,
              private businessService: BusinessService, private waitlistService: WaitlistService) { }

  ngOnInit() {

    this.preference = this.passedData.preference;
    if(this.passedData.cancelType !== undefined)
      this.cancelType = this.passedData.cancelType;
    if(this.preference.booleanMap.cancelReasonList && this.preference.labelMap.cancelReasonList) {
      this.cancelReasonListArray = this.preference.labelMap.cancelReasonList.split(/\n/);
      this.cancelReasonType = 'list';
    }
    switch (this.cancelType) {
      case 'REPEATING':
        this.repeatingAppt = this.passedData.repeatingAppt;
        this.cancelModalTitle = this.preference.labelMap.cancelRepeatingAppointmentModalTitle;
        this.cancelModalDescription = this.preference.labelMap.cancelRepeatingAppointmentModalDescription;
        this.cancelErrorMsg = this.preference.labelMap.cancelRepeatingAppointmentErrorMsg;
        this.cancelNoReasonEnteredErrorMsg = this.preference.labelMap.cancelRepeatingAppointmentNoReasonEnteredErrorMsg;
        break;
      case 'WAITLIST':
        this.waitlist = this.passedData.waitlist;
        this.cancelModalTitle = this.preference.labelMap.cancelWaitlistRegistrationModalTitle;
        this.cancelModalDescription = this.preference.labelMap.cancelWaitlistRegistrationModalDescription;
        this.cancelErrorMsg = this.preference.labelMap.cancelWaitlistRegistrationErrorMsg;
        this.cancelNoReasonEnteredErrorMsg = this.preference.labelMap.cancelWaitlistRegistrationNoReasonEnteredErrorMsg;
        break;
      case 'APPOINTMENT':
        this.appt = this.passedData.appt;
        this.cancelModalTitle = this.preference.labelMap.cancelAppointmentModalTitle;
        this.cancelModalDescription = this.preference.labelMap.cancelAppointmentModalDescription;
        this.cancelErrorMsg = this.preference.labelMap.cancelApptErrorMsg;
        this.cancelNoReasonEnteredErrorMsg = this.preference.labelMap.cancelApptNoReasonEnteredErrorMsg;
        break;

    }
  }

  cancelAppt(cancelReason: string){
    this.appt.cancelReason = cancelReason;
    this.businessService.cancelAppt(this.appt)
        .subscribe((cancelledAppt: Appointment) => {
          this.cancelling = false;
          this.cancelSuccess = true;
          setTimeout(() => {
            this.dialogRef.close(cancelledAppt);
          }, 2000);
        }, error => {
          this.cancelling = false;
          this.cancelFailed = true;
        })
  }

  cancelRepeating(cancelReason: string){
    this.repeatingAppt.cancelReason = cancelReason;
    this.businessService.cancelRepeatingAppt(this.repeatingAppt)
        .subscribe((cancelledRepeatingAppt: MultipleAppts) => {
          this.cancelling = false;
          this.cancelSuccess = true;
          setTimeout(() => {
            this.dialogRef.close(cancelledRepeatingAppt);
          }, 2000);        }, error => {
          this.cancelling = false;
          this.cancelFailed = true;
        })
  }

  cancelWaitlist(cancelReason: string){
    this.waitlist.cancelReason = cancelReason;
    let waitlistDateTimeIds: number[] = [this.waitlist.selectedDateTimeFromList.waitListDateTimeId]
    this.waitlistService.cancelWaitlistDateTime(this.waitlist.waitListId, this.waitlist.selectedDateTimeFromList.waitListDateId, waitlistDateTimeIds)
        .subscribe((cancelledWaitList: WaitList) => {
          this.cancelling = false;
          this.cancelSuccess = true;
          setTimeout(() => {
            this.dialogRef.close(cancelledWaitList);
          }, 2000);
        }, error => {
          this.cancelling = false;
          this.cancelFailed = true;
        })
  }

  cancelBookingObject(form: NgForm){
    if(!this.preference.cancelReasonOptional && (form.form.value.cancelReason === undefined || form.form.value.cancelReason === null || form.form.value.cancelReason === '')){
      this.noCancelReasonEntered = true;
    } else {
      let cancelReason = form.form.value.cancelReason;
      this.noCancelReasonEntered = false;
      this.cancelling = true;
      switch (this.cancelType) {
        case 'REPEATING':
          this.cancelRepeating(cancelReason);
          break;
        case 'WAITLIST':
          this.cancelWaitlist(cancelReason);
          break;
        case 'APPOINTMENT':
          this.cancelAppt(cancelReason);
          break;

      }
    }

  }


}
