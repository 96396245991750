import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component, EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {preference} from "../../../common-classes/app-objects.model";
import * as momentTimezone from "moment-timezone/moment-timezone";
import {ResponsiveService} from "../../../responsive.service";
import {TimeService} from '../time.service';
import {Reason} from '../../../../models/Reason.model';
import {SchedulerPreferenceService} from '../../../scheduler-preference.service';

@Component({
  selector: 'time-slot-display',
  templateUrl: './time-slot-display.component.html'
})
export class TimeSlotDisplayComponent implements OnInit, AfterViewChecked, OnChanges {
  preference: preference;
  @Input() time: any;
  @Input() startTimeProperty: string;
  @Input() endTimeProperty: string;
  @Input() reason: Reason;
  @Input() selectedDate: string;
  @Input() timeZoneCode: string;
  @Output() seatsLabelSelected = new EventEmitter(); // [TT-8571] LHB 04/12/2022
  timeZoneForMoment: string;
  startTimeDisplay: string;
  endTimeDisplay: string;
  isClassOrCourse: boolean = false;
  locale = sessionStorage.getItem('locale')
  localeForTimeConversion = sessionStorage.getItem('localeForTimeConversion')
  constructor(private cdRef: ChangeDetectorRef, private timeService: TimeService, private responsiveService: ResponsiveService,
              private schedulerPreferenceService: SchedulerPreferenceService) {
    this.preference = schedulerPreferenceService.schedulerPreference;
  }



  configureTimeDisplay(){
    this.timeZoneForMoment = this.timeService.checkToAddMomentTimezone(this.preference.allowTZChange, this.timeZoneCode);
    let startDateInTimezone = momentTimezone(this.time[this.startTimeProperty]).tz(this.timeZoneForMoment).format('YYYY-MM-DD');
    let endDateInTimezone = momentTimezone(this.time[this.endTimeProperty]).tz(this.timeZoneForMoment).format('YYYY-MM-DD');

    let additionalStartFormat = '';
    let additionalEndFormat = '';
    if(this.selectedDate !== undefined && this.selectedDate !== startDateInTimezone){
      additionalStartFormat =  'l ';
    }
    if(endDateInTimezone !== startDateInTimezone){
      additionalEndFormat = 'l ';
    }
    let startFormat = 'HH:mm';
    let endFormat = 'HH:mm';
    if(!this.preference.classCalendar && this.reason.reasonType === 'CLASS') {
      startFormat = 'llll';
      if(endDateInTimezone !== startDateInTimezone){
        endFormat = 'llll';
      } else {
        endFormat = 'LT';
      }
      if(this.preference.timeFormat24Hrs){
        startFormat = 'ddd, ll - HH:mm';
        if(endDateInTimezone !== startDateInTimezone){
          endFormat = 'ddd, ll - HH:mm';
        }
      }
    } else if(!this.preference.timeFormat24Hrs){
      startFormat = additionalStartFormat + 'LT';
      endFormat = additionalEndFormat + 'LT';
    } else {
      startFormat = additionalStartFormat + startFormat;
      endFormat = additionalEndFormat + endFormat;
    }
    if (this.preference.booleanMap.timePnlShowTimezones) {
      startFormat = startFormat + ' z';
      endFormat = endFormat + ' z';
    }
    this.startTimeDisplay = momentTimezone(this.time[this.startTimeProperty]).tz(this.timeZoneForMoment).locale(this.localeForTimeConversion).format(startFormat);
    this.endTimeDisplay = momentTimezone(this.time[this.endTimeProperty]).tz(this.timeZoneForMoment).locale(this.localeForTimeConversion).format(endFormat);
    if (this.startTimeDisplay === 'Invalid date') {
      this.startTimeDisplay = momentTimezone(this.time[this.startTimeProperty]).tz(this.timeZoneForMoment).locale('en').format(startFormat);
    }
    if (this.endTimeDisplay === 'Invalid date') {
      this.endTimeDisplay = momentTimezone(this.time[this.endTimeProperty]).tz(this.timeZoneForMoment).locale('en').format(endFormat);
    }
    // let classScheduleIdsForConsole = [4435891];
    // let timeSlotIdsForConsole = ['2020-10-21:1845-1945','2020-10-21:1345-1445'];
    // if(timeSlotIdsForConsole.indexOf(this.time.timeSlotId) !== -1 || classScheduleIdsForConsole.indexOf(this.time.classScheduleId) !== -1) {
    //   console.log("/**************START****************/");
    //   console.log("this.startTimeDisplay " + JSON.stringify(this.startTimeDisplay));
    //   console.log("this.timeZoneCode " + this.timeZoneCode);
    //   console.log("this.time.timeSlotId " + JSON.stringify(this.time.timeSlotId));
    //   console.log("this.time.timeString " + JSON.stringify(this.time.timeString));
    //   console.log("this.time[this.startTimeProperty] " + JSON.stringify(this.time[this.startTimeProperty]));
    //   console.log("this.time[this.endTimeProperty] " + JSON.stringify(this.time[this.endTimeProperty]));
    //   console.log("this.timeZoneForMoment " + JSON.stringify(this.timeZoneForMoment));
    //   console.log("startDateInTimezone " + JSON.stringify(startDateInTimezone));
    //   console.log("endDateInTimezone " + JSON.stringify(endDateInTimezone));
    //   console.log("this.selectedDate " + JSON.stringify(this.selectedDate));
    //   console.log("/**************END****************/");
    // }
    this.cdRef.detectChanges();
  }

  ngOnInit() {
    if(this.reason.reasonType === 'CLASS' || this.reason.reasonType === 'COURSE'){
      this.isClassOrCourse = true;
    } else {
      this.isClassOrCourse = false;
    }
    this.configureTimeDisplay();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.timeZoneCode !== undefined && changes.timeZoneCode.previousValue !== null && changes.timeZoneCode.previousValue !== changes.timeZoneCode.currentValue){
      this.configureTimeDisplay();
    }
  }

  ngAfterViewChecked(): void {
    if(this.reason.reasonType === 'CLASS'){
      this.responsiveService.checkTimeSlotWidthForOverflow(this.time.classScheduleId, 'CLASS')
    }
    this.cdRef.detectChanges();
  }

}
