import {AfterViewChecked, Component, OnInit} from '@angular/core';
import {BusinessService} from "../../../../business.service";
import {
    Client,
    CSFilterParameterBean,
    movePanels,
    preference
} from "../../../../common-classes/app-objects.model";
import {ClientAccountService} from "../../../client-account.service";
import {ActivatedRoute, Router} from "@angular/router";
import {TagsService} from "../../../../tags.service";
import {AuthService} from "../../../../auth/auth.service";
import {TimeService} from "../../../../scheduler/time/time.service";
import {DatePipe, TitleCasePipe} from "@angular/common";
import {MatDialog} from "@angular/material/dialog";
import {
    CancelAppointmentComponent,
    CancelBookingObjModalData
} from "../../cancel-appointment/cancel-appointment.component";
import {ErrorModalComponent} from "../../../../modals/error-modal/error-modal.component";
import {ResponsiveService} from "../../../../responsive.service";
import {HttpErrorResponse} from "@angular/common/http";
import {AddToCalendarComponent} from "../../../../scheduler/common-components/add-to-calendar/add-to-calendar.component";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {Appointment} from '../../../../../models/Appointment.model';
import {SchedulerService} from '../../../../scheduler/scheduler.service';
import {SchedulerPreferenceService} from '../../../../scheduler-preference.service';
import {Staff} from '../../../../../models/Staff.model';
import {schedulerPanel, SchedulerPanelValues} from '../../../../../models/SchedulerPanel.model';
import {SelectedDateTimeObj} from '../../../../../models/SelectedDateTimeObj.model';

@Component({
  selector: 'app-appointment-detail',
  templateUrl: './appointment-detail.component.html',
    styleUrls: ['../booking-object-details.component.css']
})
export class AppointmentDetailComponent implements OnInit, AfterViewChecked {
  preference: preference;
  selectedClient: Client;
  calendarId: string;
  apptHash: string;
  appt: Appointment;
  apptLoaded: boolean = false;
  apptLoading: boolean = true;
  errorLoading: boolean = false;
  apptDetailText: string;
  apptStatus: string;
  chipBaseStyle: string;
  chipClass: string;
  reschedulingAppt: boolean = false;
  rescheduleAllowed: boolean = false;
  rescheduleNotAllowedReason: string = null;
  rescheduleSaving: boolean = false;
  errorReschedulingAppointment: boolean = false;
  cancelNotAllowed: boolean = true;
  cancelNotAllowedReason: string = 'apptCancelPastTimeframe';
  clientConfirmFlow: boolean = false;
  apptCanBeConfirmed: boolean = false;
  clientApptCannotBeConfirmedMsg: string = null;
  rescheduleConfirmationMsg: string = '';
  showRescheduleConfirm: boolean = false;
  timePanel: schedulerPanel;
  CSFilterObj: CSFilterParameterBean;
  directionComingFrom: string = 'reschedule';
    showAddToCalendarBtn: boolean = true;
    showBackButton: boolean = true;
  inputValues: any = [];
    noAppointmentsAvailable = false;

  constructor(private businessService: BusinessService, private clientAccountService: ClientAccountService, private timeService: TimeService,
              private schedulerService: SchedulerService, private schedulerPreferenceService: SchedulerPreferenceService,
              private datePipe: DatePipe, private titleCasePipe: TitleCasePipe, private dialog: MatDialog, private responsiveService: ResponsiveService,
              private route: ActivatedRoute, private router: Router, private tagsService: TagsService, private authService: AuthService, private addToCalendarSheet: MatBottomSheet) {
      this.preference = schedulerPreferenceService.schedulerPreference;
  }

    addToCalendar(){
        this.addToCalendarSheet.open(AddToCalendarComponent, {data: {appointments: [this.appt]}});
    }

  rescheduleAppt(){
      if(!this.rescheduleAllowed){
          const dialogRef = this.dialog.open(ErrorModalComponent, {maxWidth: this.responsiveService.getMaxWidthForModals(), data: {preference: this.preference, appt: this.appt, errorType: this.rescheduleNotAllowedReason}})
      } else if(this.preference.maxClientRescheduleCount !== null && this.appt.clientRescheduleCount >= this.preference.maxClientRescheduleCount){//TT-6115
          const dialogRef = this.dialog.open(ErrorModalComponent, {maxWidth: this.responsiveService.getMaxWidthForModals(), data: {preference: this.preference, errorType: 'apptMaxRescheduleCountError'}})
      } else {
          this.CSFilterObj = this.schedulerService.newCSFilterObj();
          this.appt.location.variableAddress = this.appt.address; // LHB 10/2/2020 TT-7047
          this.appt.location.description = this.appt.address; // LHB 10/2/2020 TT-7047
          this.CSFilterObj.reasonIdList = [this.appt.reason.reasonId];

          this.appt.reason.clientDuration = (Math.floor(this.appt.clientEndTime/100) - Math.floor((+this.appt.clientStartTime)/ 100)) * 60 + (this.appt.clientEndTime % 100) - ((+this.appt.clientStartTime) % 100);

          let reasonInputValue = {panel: 'reasons', 'values': [{'field': 'reason', 'value': this.appt.reason}]};
          let staffInputValue = {panel: 'staff', 'values': [{'field': 'staff', 'value': new Staff(null, this.preference.labelMap.labelNoPreference)}]};
          let locationInputValue = {panel: 'locations', 'values': [{'field': 'location', 'value': this.appt.location}]};
          this.CSFilterObj.locationIdList = [this.appt.location.locationId];
          this.CSFilterObj.staffIdList = null;
          if(this.preference.restrictReschedToSameStaff) {
              staffInputValue = {panel: 'staff', 'values': [{'field': 'staff', 'value': this.appt.staff}]};
              this.CSFilterObj.staffIdList = [this.appt.staff.professionalId];
          }

          this.inputValues = [reasonInputValue, staffInputValue, locationInputValue]
          if(this.appt.locationSuperGroup !== null || this.appt.locationGroup !== null ||
              (this.appt.reasonSuperGroup !== undefined && this.appt.reasonSuperGroup !== null) || this.appt.reasonGroup !== null){
              this.CSFilterObj.groupIdList = [];
              if(this.appt.locationSuperGroup !== undefined && this.appt.locationSuperGroup !== null){
                  this.CSFilterObj.groupIdList.push((this.appt.locationSuperGroup.groupId));
                  this.CSFilterObj.locationSuperGroupIdList = [this.appt.locationSuperGroup.groupId]
                  let locationSuperGroupInputValue = {panel: 'locationSuperGroups', 'values': [{'field': 'locationSuperGroup', 'value': this.appt.locationSuperGroup}]};
                  this.inputValues.push(locationSuperGroupInputValue);
              }
              if(this.appt.locationGroup !== undefined && this.appt.locationGroup !== null){
                  this.CSFilterObj.groupIdList.push((this.appt.locationGroup.groupId));
                  this.CSFilterObj.locationGroupIdList = [this.appt.locationGroup.groupId]
                  let locationGroupInputValue = {panel: 'locationGroups', 'values': [{'field': 'locationGroup', 'value': this.appt.locationGroup}]};
                  this.inputValues.push(locationGroupInputValue);
              }
              if(this.appt.reasonSuperGroup !== undefined && this.appt.reasonSuperGroup !== null){
                  this.CSFilterObj.groupIdList.push((this.appt.reasonSuperGroup.groupId));
                  this.CSFilterObj.reasonSuperGroupIdList = [this.appt.reasonSuperGroup.groupId]
                  let reasonSuperGroupInputValue = {panel: 'reasonSuperGroups', 'values': [{'field': 'reasonSuperGroup', 'value': this.appt.reasonSuperGroup}]};
                  this.inputValues.push(reasonSuperGroupInputValue);
              }
              if(this.appt.reasonGroup !== undefined && this.appt.reasonGroup !== null){
                  this.CSFilterObj.groupIdList.push((this.appt.reasonGroup.groupId));
                  this.CSFilterObj.reasonGroupIdList = [this.appt.reasonGroup.groupId]
                  let reasonGroupInputValue = {panel: 'reasonGroups', 'values': [{'field': 'reasonGroup', 'value': this.appt.reasonGroup}]};
                  this.inputValues.push(reasonGroupInputValue);
              }
          }
          if(this.appt.client.locale !== undefined && this.appt.client.locale !== null){
              this.CSFilterObj.locale = this.appt.client.locale
          }
          if(this.appt.client.timeZoneCode !== undefined && this.appt.client.timeZoneCode !== null){
              this.CSFilterObj.clientTimeZone = this.appt.client.timeZoneCode.timeZoneCode;
          }
          this.CSFilterObj.rescheduling = true;
          this.timePanel = new schedulerPanel(SchedulerPanelValues.time, null, true, 8, false, 'timeTitle', 'timeText', 'timePnlErrorLoading', true);
          this.timePanel.formGroup = this.schedulerService.getPanelForm(this.timePanel.panel, this.preference.schedulerPreferenceFieldDefnList)
          this.reschedulingAppt = true;

      }


  }
  saveRescheduled(moveData: movePanels){
      this.reschedulingAppt = false;
      this.noAppointmentsAvailable = moveData.noAppointmentAvail;
      if(moveData.direction === 'next'){
          this.rescheduleSaving = true;
          let selectedDateTime: SelectedDateTimeObj = moveData.panel.formGroup.value.selectedDateTime;
          for(let prop in selectedDateTime){
              switch (prop) {
                  case 'clientEndDate':
                  case 'clientStartDate':
                      this.appt[prop] = this.datePipe.transform(selectedDateTime[prop], 'yyyy-MM-dd', '');
                      break;
                  case 'recurringScheduleId':
                      this.appt['recurringSchedule'] = {'recurringScheduleId': selectedDateTime[prop]};
                      break;
                  default:
                      this.appt[prop] = selectedDateTime[prop];
              }
          }

          if(selectedDateTime.calTimeSlot !== null) {//LHB 06/23/2020 TT-6736 fix
              let initialStaffFound = false;
              for (let i = 0, x = selectedDateTime.calTimeSlot.units.length; i < x; i++) {
                  if (selectedDateTime.calTimeSlot.units[i].professionalId === this.appt.staff.professionalId)
                      initialStaffFound = true;
              }
              if (!initialStaffFound) {
                  this.appt.staff = new Staff(selectedDateTime.calTimeSlot.units[0].professionalId, null);
              }
          } else if(selectedDateTime.classScheduleId !== undefined && selectedDateTime.classScheduleId !== null){
              if(selectedDateTime.professionalId !== undefined && selectedDateTime.professionalId !== null && selectedDateTime.professionalId !== this.appt.staff.professionalId){
                  this.appt.staff = new Staff(selectedDateTime.professionalId, null);
              }
          }
          this.appt.startDate = this.appt.clientStartDate;
          this.appt.endDate = this.appt.clientEndDate;
          this.businessService.updateAppt(this.appt)
              .subscribe((updatedAppt: Appointment) => {
                  // this.rescheduleConfirmationMsg
                  this.errorReschedulingAppointment = false;
                  this.showRescheduleConfirm = true;
                  this.configureApptDetail(updatedAppt);
              }, (error: HttpErrorResponse) => {
                  this.errorReschedulingAppointment = true;
                  this.rescheduleSaving = false;
                  this.apptLoading = false;
              })

      }
  }
  cancelAppt(){
      if(this.cancelNotAllowed) {
          const dialogRef = this.dialog.open(ErrorModalComponent, {maxWidth: this.responsiveService.getMaxWidthForModals(),
              data: {preference: this.preference, errorType: this.cancelNotAllowedReason, appt: this.appt}})
      } else {
          let dataToPass: CancelBookingObjModalData = new CancelBookingObjModalData();
          dataToPass.preference = this.preference;
          dataToPass.cancelType = 'APPOINTMENT';
          dataToPass.appt = this.appt;
          const dialogRef = this.dialog.open(CancelAppointmentComponent,
              {
                  maxWidth: this.responsiveService.getMaxWidthForModals(),
                  data: dataToPass
              })
          dialogRef.afterClosed().subscribe((cancelledAppt: Appointment) => {
              if (cancelledAppt !== undefined) {
                  this.configureApptDetail(cancelledAppt);
              }
          })
      }
  }
  goBack(){
      if(this.authService.isAuth()) {
          if(history.state !== undefined && history.state !== null && history.state.onMyProfile !== undefined && history.state.onMyProfile !== null && history.state.onMyProfile === true){
              this.router.navigate(['/my-account/profile'], {relativeTo: this.route.root, state: {showPackages: true}})
          } else if(history.state !== undefined && history.state !== null && history.state.onRepeatingDetail !== undefined && history.state.onRepeatingDetail !== null && history.state.onRepeatingDetail === true){
              this.router.navigate(['/my-account/repeating/' + this.appt.recurringAppointmentId], {relativeTo: this.route.root})
          } else
              this.router.navigate(['/my-account/appts'], {relativeTo: this.route.root, state: {listStatus: this.appt.status.toUpperCase()}})
      } else if (!this.businessService.business.acceptOnlineAppointments) {
          this.clientAccountService.movedFromDetailWhenBookingNotAllowed.next();
          this.router.navigate(['/'], {relativeTo: this.route.root});
      } else {
          this.router.navigate(['../../scheduler'], {relativeTo: this.route});
      }
  }

  configureApptDetail(appt: Appointment){
      if(appt === null) {
          const dialogRef = this.dialog.open(ErrorModalComponent, {maxWidth: this.responsiveService.getMaxWidthForModals(),
              data: {preference: this.preference, errorType: 'apptDoesNotBelongToAccount'}})
          dialogRef.afterClosed().subscribe( data => {
              this.goBack();
          })
      } else {
          this.appt = appt;
          this.apptDetailText = this.tagsService.convertApptPropertiesToTags('appointmentDetailText', this.preference, this.appt);
          this.apptStatus = this.appt.status.replace(new RegExp('_', 'g'), ' ');
          this.apptStatus = this.titleCasePipe.transform(this.apptStatus);
          if(window.location.href.indexOf('businessWeb/web/clientConfirm') !== -1){
              this.clientConfirmFlow = true;
              if(this.appt.status.toUpperCase() === 'OPEN')
                  this.apptCanBeConfirmed = true;
              else {
                  this.apptCanBeConfirmed = false;
                  this.clientApptCannotBeConfirmedMsg = this.tagsService.convertApptPropertiesToTags('clientApptCannotBeConfirmedMsg', this.preference, this.appt);
              }
          }
          switch (this.appt.status.toUpperCase()) {
              case 'OPEN':
                  this.chipBaseStyle ='primary';
                  this.chipClass ='apptStatusOpenChip';
                  break;
              case 'COMPLETED':
                  this.chipBaseStyle ='success';
                  this.chipClass ='apptStatusCompletedChip';
                  break;
              case 'CANCELLED':
                  this.chipBaseStyle ='error';
                  this.chipClass ='apptStatusCancelledChip';
                  break;
              case 'NO_SHOW':
                  this.chipBaseStyle ='warning';
                  this.chipClass ='apptStatusNoShowChip';
                  break;
          }
          this.rescheduleAllowed = false;
          this.cancelNotAllowed = true;
          if(this.preference.rescheduleHrs === null) {
              this.rescheduleNotAllowedReason = 'reschedulingsNotAllowedByBusiness';
          } else if(this.appt.status.toUpperCase() !== 'OPEN'){
              this.rescheduleNotAllowedReason = 'apptRescheduleNotInOpenStatus';
          } else if(this.appt.client.status.toUpperCase() === this.clientAccountService.onHoldStatus){
              this.rescheduleNotAllowedReason = 'rescheduleNotAllowedClientOnHold';
          } else if(this.preference.rescheduleHrs !== null && this.appt.status.toUpperCase() === 'OPEN'){
              let rescheduleHrsInMs = this.timeService.oneHrMs * this.preference.rescheduleHrs;
              let rescheduleAllowedUntil = Number(this.appt.clientStartDateTimeUTC) - rescheduleHrsInMs;
              let currentDateTimeMs = new Date().getTime();
              if(currentDateTimeMs < rescheduleAllowedUntil){
                  this.rescheduleAllowed = true;
              } else {
                  this.rescheduleNotAllowedReason = 'apptReschedulePastTimeframe';
              }
          }
          if(this.appt.status.toUpperCase() !== 'OPEN'){
              this.cancelNotAllowedReason = 'apptCancelNotInOpenStatus';
          } else if(this.appt.client.status === this.clientAccountService.onHoldStatus){
              this.cancelNotAllowedReason = 'cancelNotAllowedClientOnHold';
          } else if(this.preference.cancelHrs !== -1 && this.appt.status.toUpperCase() === 'OPEN'){
              let cancelHrsInMs = this.timeService.oneHrMs * this.preference.cancelHrs;
              let cancelAllowedUntil =  Number(this.appt.clientStartDateTimeUTC) - cancelHrsInMs;
              let currentDateTimeMs = new Date().getTime();
              if(currentDateTimeMs < cancelAllowedUntil){
                  this.cancelNotAllowed = false;
              } else {
                  this.cancelNotAllowedReason = 'apptCancelPastTimeframe';
              }
          }
          this.apptLoaded = true;
          this.apptLoading = false;
          this.rescheduleSaving = false;
          if(this.showRescheduleConfirm){
              setTimeout(() => {
                  this.showRescheduleConfirm = false;
              }, 5000)
          }
      }

  }
  getApptDetail(){
    let calendarIdString = this.route.params.subscribe(params => {
        this.apptHash = params['apptHash'];
    })
    if(history.state !== undefined && history.state !== null && history.state.appt !== undefined && history.state.appt !== null){
        this.configureApptDetail(history.state.appt)
    } else {
        this.businessService.getApptDetail(this.apptHash)
            .subscribe((appt: Appointment) => {
                this.configureApptDetail(appt)
            }, (error: HttpErrorResponse) => {
                this.errorLoading = true;
                this.apptLoading = false;
            })
    }

  }

  ngAfterViewChecked(){
      this.responsiveService.setHeightForEmbedAccount();
  }

  ngOnInit() {
      if(!this.authService.isAuth() &&
          (window.location.pathname.indexOf("manage") !== -1 || window.location.pathname.indexOf("clientConfirm") !== -1))
          this.showBackButton = false;
      this.showAddToCalendarBtn = this.preference.showAddToCalendar;
      this.selectedClient = this.clientAccountService.getSelectedClient(this.preference);
      this.getApptDetail();


  }

}
