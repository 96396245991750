import {AfterViewChecked, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CSFilterParameterBean, preference} from "../../../common-classes/app-objects.model";
import {ResponsiveService} from "../../../responsive.service";

@Component({
    selector: 'reason-groups-filter',
    templateUrl: './reason-groups-filter.component.html'
})
export class ReasonGroupsFilterComponent implements OnInit, AfterViewChecked {
    @Output() onSearchObjects = new EventEmitter<string>();
    @Input() preference: preference;
    @Input() CSFilterObj: CSFilterParameterBean;
    @Input() searchComplete: boolean;
    filterValue: string;

    constructor(private responsiveService: ResponsiveService) { }

    ngAfterViewChecked(): void {
        this.responsiveService.configureHorizontalSearch('filterSearchField', 'filterSearchBtn')
    }

    ngOnInit() {
    }

    searchFilters(filterValue: string){
        this.onSearchObjects.emit(filterValue);
    }
    checkEnter(event: KeyboardEvent, filterValue: string){
        if(event.key === 'Enter'){
            this.searchFilters(filterValue);
        }
    }

}