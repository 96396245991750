import {DatePipe} from '@angular/common';
import {Injectable, Injector} from "@angular/core";
import {
    Client,
    CSFilterParameterBean,
    currency,
    ExistingSessionObject,
    Invoice,
    InvoiceItem,
    landingPageObjects,
    miniWebsite,
    MultipleAppts,
    PaymentMap,
    RecurringAppointment
} from "./common-classes/app-objects.model";
import {TimeZoneCode} from "./scheduler/time/time-objects.model";
import {HttpClient, HttpParams} from "@angular/common/http";
import {map} from "rxjs/operators";
import {catchError} from "rxjs/internal/operators";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Subject} from "rxjs";
import {ClientInfoService} from "./scheduler/client-info/client-info.service";
import * as $ from 'jquery';
import {StyleSheetService} from "./styleSheetService";
import {PackageSold} from "./store/packages/packages.model";
import {StoredCardInfo} from "./client-account/client-profile/client-profile-container/client-profile-container.component";
import {TimeService} from './scheduler/time/time.service';
import {MatDialog} from '@angular/material/dialog';
import {EmailSettingsService} from './email-settings/email-settings.service';
import {Group} from '../models/Group.model';
import {WaitList} from '../models/WaitList.model';
import {Appointment} from '../models/Appointment.model';
import {ConfigListService} from './config-list.service';
import {Business} from '../models/Business.model';
import {CustomField} from '../models/CustomField.model';
import {Location} from '../models/Location.model';
import {Staff} from '../models/Staff.model';
import {SchedulerPanelValues} from '../models/SchedulerPanel.model';
import { AppointmentIntent } from 'src/models/AppointmentIntent.model';

@Injectable()

export class BusinessService {
    sidenavChange = new Subject<boolean>();
    sidenavOpen:boolean = false;
    locale: string;
    landingPageObjectLoaded = new Subject<boolean>();
    landingPageObjectErrorLoading = new Subject<boolean>();
    business: Business;
    backOfficeURL: string;
    currency: currency;
    doubleBookedTimeErrorMsg: string = "ALREADY_BOOKED";
    sessionObject: ExistingSessionObject;
    businessTimezone: string;
    businessConfigTest: boolean;
    defaultGateway: string;
    requirePreAuthPayment: boolean;
    requirePayment: boolean;
    requireStaffConfirmation: boolean;
    isSandboxAccount: boolean;
    clientInfoService: ClientInfoService;
    styleSheetService: StyleSheetService;
    timeService: TimeService;
    emailSettingsService: EmailSettingsService;
    private apiUrl: string;
    private apiUrlSecure: string;
    configListService: ConfigListService;
    defaultSquareLocationId: string;
    accepttips: boolean;
    tiprate1: number;
    tiprate2: number;
    tiprate3: number;
    appointmentIntentAllowed: boolean;
    allowSms: boolean;
    constructor(private http: HttpClient, private datePipe: DatePipe,
                private injector: Injector, private dialog: MatDialog){
        try {
            this.locale = sessionStorage.getItem('locale');
            // this.showTurnOnAllowCookiesMessage();
        } catch (e) {
            this.locale = 'en-US';
        }
        try {
            this.apiUrl = sessionStorage.getItem('csAPIBase') + 'cs/';
            this.apiUrlSecure = sessionStorage.getItem('csAPIBase') + 'csl/'

        } catch (e) {

        }
        this.clientInfoService = injector.get(ClientInfoService);
        this.styleSheetService = injector.get(StyleSheetService);
        this.timeService = injector.get(TimeService);
        this.emailSettingsService = injector.get(EmailSettingsService);
        this.configListService = injector.get(ConfigListService);
    }

    private handleError(error: any): Promise<any>{
        // console.log(error);
        return Promise.reject(error);
    }

    toggleSidenav(){
        this.sidenavOpen = !this.sidenavOpen;
        this.sidenavChange.next(this.sidenavOpen);
    }

    checkToggleSidenav(){
        if(this.sidenavOpen){
            this.toggleSidenav();
        }
    }

    setBusinessItems(landingPageObjects: landingPageObjects) {
        this.business = landingPageObjects.business;
        this.backOfficeURL = landingPageObjects.backOfficeURL;
        this.currency = landingPageObjects.currency;
        this.businessTimezone = this.business.timeZoneCode.timeZoneCode;
        this.timeService.setBusinessTimezone(this.businessTimezone);
        this.businessConfigTest = landingPageObjects.businessConfigTest;
        this.defaultGateway = landingPageObjects.defaultGateway;
        this.requirePreAuthPayment = landingPageObjects.requirePreAuthPayment;
        this.requireStaffConfirmation = landingPageObjects.requireStaffConfirmation;
        this.requirePayment = landingPageObjects.requirePayment;
        this.defaultSquareLocationId = landingPageObjects.defaultSquareLocationId;
        this.isSandboxAccount = this.business.sandboxAccount;
        this.accepttips = landingPageObjects.accepttips;
        this.tiprate1 = landingPageObjects.tiprate1;
        this.tiprate2 = landingPageObjects.tiprate2;
        this.tiprate3 = landingPageObjects.tiprate3;
        this.appointmentIntentAllowed = landingPageObjects.appointmentIntentAllowed || false;
        this.allowSms = landingPageObjects.allowSms || false;
    }

    recordAuditTrailNavigation(page: string) {
        return this.http
            .post<any>(this.apiUrl + 'navigate/' + page, null, {observe: "body", responseType: 'json'})
            .pipe(
                map((responseData: any) => {
                    return responseData;
                }),
                catchError(this.handleError)
            )
    }

    getLocations(CSFilterObj: CSFilterParameterBean){
        CSFilterObj.locationIdList = null;
        return this.http
            .post<Location[]>(this.apiUrl + 'locations', CSFilterObj, {observe: "body", responseType: 'json'})
            .pipe(
                map((responseData: Location[]) => {
                    if (responseData)
                        for (let i = 0, x = responseData.length; i < x; i++)
                            responseData[i].displayName = responseData[i].locationName;
                    return responseData;
                }),
                catchError(this.handleError)
            )
    }

    validateVariableLocationAddress(address: string){
        return this.http
            .post<string[]>(this.apiUrl + 'validateAddress', address, {observe: "body", responseType: 'json'})
            .pipe(
                map((responseData: string[]) => {
                    return responseData;
                }),
                catchError(this.handleError)
            )
    }

    getStaff(CSFilterObj: CSFilterParameterBean){
        CSFilterObj.staffIdList = null;
        return this.http
            .post<Staff[]>(this.apiUrl + 'staff', CSFilterObj, {observe: "body", responseType: 'json'})
            .pipe(
                map((responseData: Staff[]) => {
                    if (responseData)
                        for (let i = 0, x = responseData.length; i < x; i++)
                            responseData[i].displayName = responseData[i].fullName;
                    return responseData;
                }),
                catchError(this.handleError)
            )
    }

    getGroups(groupType: string, CSFilterObj: CSFilterParameterBean){
        switch(groupType){
            case 'SERVICE':
                CSFilterObj.reasonGroupIdList = null;
                break;
            case 'SERVICE_GROUP':
                CSFilterObj.reasonSuperGroupIdList = null;
                break;
            case 'LOCATION':
                CSFilterObj.locationGroupIdList = null;
                break;
            case 'LOCATION_GROUP':
                CSFilterObj.locationSuperGroupIdList = null;
                break;
        }
        return this.http
            .post<Group[]>(this.apiUrl + 'groups/list/' + groupType, CSFilterObj, {observe: "body", responseType: 'json'})
            .pipe(
                map((responseData: Group[]) => {
                    if (responseData)
                        for (let i = 0, x = responseData.length; i < x; i++)
                            responseData[i].displayName = responseData[i].groupName;
                    return responseData;
                }),
                catchError(this.handleError)
            )
    }

    getTimezones(){
        return this.http
            .get<TimeZoneCode>(this.apiUrl + 'timezones', {observe: "body", responseType: 'json'})
            .pipe(
                map((responseData: TimeZoneCode) => {
                    return responseData;
                }),
                catchError(this.handleError)
            )
    }

    createUpdateClient(client: Client, connectClientAccountId?: number, checkCardOnFile?: boolean){
        let paramsToPass = new HttpParams();
        if(connectClientAccountId !== undefined && connectClientAccountId !== null)
            paramsToPass = paramsToPass.append('connectClientAccountId', JSON.stringify(connectClientAccountId));
        if(checkCardOnFile !== undefined && checkCardOnFile !== null)
            paramsToPass = paramsToPass.append('checkCardOnFile', JSON.stringify(checkCardOnFile));
        return this.http
            .post<Client>(this.apiUrl + 'clients', client, {observe: "body", responseType: 'json', params: paramsToPass})
            .pipe(
                map((responseData: Client) => {
                    return responseData;
                }),
                catchError((err, caught) => {
                    return this.handleError(err.error.message);
                })
                //catchError(this.handleError)
            )
    }

    getActiveApptCount(businessId: number, clients: Client[]){
        const clientsNoAddConnected = []; // LHB 3/5/2021 TT-7487 -- get list of clients without the "Add Connected Client" in the list so the backend doesn't error out
        for (let i = 0, x = clients.length; i < x; i++)
            if (clients[i].clientId)
                clientsNoAddConnected.push(clients[i]);
        return this.http
            .post<any>(this.apiUrl + 'activeApptCount/' + businessId, clientsNoAddConnected, {observe: "body", responseType: 'json'})
            .pipe(
                map((responseData: any) => {
                    return responseData;
                }),
                catchError(this.handleError))
    }

    addPropsToAppointment(appointment: Appointment) {
        const appt: Appointment = new Appointment();
        appt.copyPropsFromAPI(appointment);
        // LHB 09/16/2021 TT-8134
        const qrCodeUrl = "https://chart.apis.google.com/chart?cht=qr&chs=100x100&chl=" + appt.calendarId;
        appt.apptQRCode = "<img src='" + qrCodeUrl + "'></img>";
        const qrCodeCheckinUrl = "https://chart.apis.google.com/chart?cht=qr&chs=100x100&chl=" + this.backOfficeURL + "/backoffice/app/home#/checkin/" + appt.calendarId;
        appt.apptCheckInQRCode = "<img src='" + qrCodeCheckinUrl + "'></img>";
        return appt;
    }

    createAppt(appt: Appointment){
        return this.http
            .post<Appointment>(this.apiUrl + 'appointments', appt, {observe: "body", responseType: 'json'})
            .pipe(
                map((responseData: Appointment) => {
                    responseData = this.addPropsToAppointment(responseData);
                    return responseData;
                }),
                catchError(this.handleError)
            )
    }

    updateAppt(appt: Appointment){
        return this.http
            .put<Appointment>(this.apiUrl + 'appointments', appt, {observe: "body", responseType: 'json'})
            .pipe(
                map((responseData: Appointment) => {
                    responseData = this.addPropsToAppointment(responseData);
                    return responseData;
                }),
                catchError(this.handleError)
            )
    }

    cancelAppt(appt: Appointment){
        return this.http
            .put<Appointment>(this.apiUrl + 'appointments/cancel/' + appt.calendarId, appt, {observe: "body", responseType: 'json'})
            .pipe(
                map((responseData: Appointment) => {
                    responseData = this.addPropsToAppointment(responseData);
                    return responseData;
                }),
                catchError(this.handleError)
            )
    }

    cancelRepeatingAppt(repeatingAppt: RecurringAppointment){
        return this.http
            .put<MultipleAppts>(this.apiUrl + 'recurringappointment/cancel/' + repeatingAppt.recurringAppointmentId, repeatingAppt, {observe: "body", responseType: 'json'})
            .pipe(
                map((responseData: MultipleAppts) => {
                    return responseData;
                }),
                catchError(this.handleError)
            )
    }

    // Used while testing TT-2274 to prompt appt to go through cancellation flow
    // cancelApptNoPay(appt: Appointment){
    //     return this.http
    //         .post<Appointment>(this.apiUrl + 'cancelForNonPayment', appt, {observe: "body", responseType: 'json'})
    //         .pipe(
    //             map((responseData: Appointment) => {
    //                 return responseData;
    //             }),
    //             catchError(this.handleError)
    //         )
    // }

    createRepeatingAppt(repeatingAppt: RecurringAppointment){
        return this.http
            .post<MultipleAppts>(this.apiUrl + 'recurringAppointments', repeatingAppt, {observe: "body", responseType: "json"})
            .pipe(
                map((responseData: MultipleAppts) => {
                    return responseData;
                })
            )
    }

    createWaitList(waitLists: WaitList[]){
        return this.http
            .post<WaitList[]>(this.apiUrl + 'waitlist', waitLists, {observe: "body", responseType: "json"})
            .pipe(
                map((responseData: WaitList[]) => {
                    return responseData;
                })
            )
    }

    getStripeConfigDefault(businessId: number){
        return this.http
            .get<PaymentMap>(this.apiUrl + 'getStripeConfig/' + businessId, {observe: "body", responseType: 'json'})
            .pipe(
                map((responseData: PaymentMap) => {
                    return responseData;
                }),
                catchError(this.handleError)
            )
    }

    getStripeConfigByInvoice(businessId: number, invoiceId: number){
        return this.http
            .get<PaymentMap>(this.apiUrl + 'getStripeConfig/' + businessId + '/' + invoiceId, {observe: "body", responseType: 'json'})
            .pipe(
                map((responseData: PaymentMap) => {
                    return responseData;
                }),
                catchError(this.handleError)
            )
    }

    getApptDetail(apptHash: string){
        return this.http
            .get<Appointment>(this.apiUrl + 'appointment/' + apptHash, {
                observe: 'body',
                responseType: 'json',
            })
            .pipe(
                map((responseData: Appointment) => {//MAP VIDEO 255 ON ANGULAR 8 COMPLETE GUIDE
                    responseData = this.addPropsToAppointment(responseData);
                    return responseData;
                }),
                catchError(this.handleError)
            )
    }

    getRepeatingApptDetail(repeatingApptHash: string){
        return this.http
            .get<MultipleAppts>(this.apiUrl + 'recurringappointment/' + repeatingApptHash, {
                observe: 'body',
                responseType: 'json',
            })
            .pipe(
                map((responseData: MultipleAppts) => {//MAP VIDEO 255 ON ANGULAR 8 COMPLETE GUIDE
                    return responseData;
                }),
                catchError(this.handleError)
            )
    }

    getApptICS(apptHash: string){
        // @ts-ignore
        return this.http.get(this.apiUrl + 'appointment/' + apptHash + '/ical', {responseType: 'blob'})
            .pipe(
                map((file: Blob) => {
                    return file;
                }),
                catchError(this.handleError)
            )
    }

    getInvoiceDetail(invoiceHash: string){
        return this.http
            .get<Invoice>(this.apiUrl + 'invoices/invoiceHash/' + invoiceHash, {
                observe: 'body',
                responseType: 'json',
            })
            .pipe(
                map((responseData: Invoice) => {//MAP VIDEO 255 ON ANGULAR 8 COMPLETE GUIDE
                    return responseData;
                }),
                catchError(this.handleError)
            )
    }


    getInvoicePaymentMap(invoiceId: number, paymentAmount: number){
        let paramsToPass = new HttpParams();
        paramsToPass = paramsToPass.append('paymentAmount', JSON.stringify(paymentAmount));
        return this.http
            .get<{paymentMap: PaymentMap, storedCardInfo: StoredCardInfo}>(this.apiUrl + 'pay/invoice/' + invoiceId, {observe: "body", responseType: 'json', params: paramsToPass})
            .pipe(
                map((responseData: {paymentMap: PaymentMap, storedCardInfo: StoredCardInfo}) => {
                    return responseData;
                }),
                catchError(this.handleError)
            )
    }



    chargeCardOnFile(invoice: Invoice, cardId: string, cardType: string, amt: number, verificationToken: string, tipRate: string, tipAmount: number){
        let paramsToPass = new HttpParams();
        paramsToPass = paramsToPass.append('amt', JSON.stringify(amt))
        if(cardType === 'STRIPE') {
            paramsToPass = paramsToPass.append('stripePaymentMethodId', cardId);
            if(tipRate && tipAmount){
                paramsToPass = paramsToPass.append('tipRate', tipRate);
                paramsToPass = paramsToPass.append('tipAmount', JSON.stringify(tipAmount));

            }
        }
        if(cardType === 'SQUARE')
            paramsToPass = paramsToPass.append('squareCardId', cardId);

        if(verificationToken)
            paramsToPass = paramsToPass.append('verificationToken', verificationToken);

        const queryString = window.location.href;
        const urlParams = new URLSearchParams(queryString);
        console.log(urlParams.get('username'));
        let username = urlParams.get('username');
        paramsToPass = paramsToPass.append("username", username);

        return this.http
            .post<Invoice>(this.apiUrl + 'payInvoice/' + invoice.invoiceHash, {}, {observe: "body", responseType: "json", params: paramsToPass})
            .pipe(
                map((responseData: Invoice) => {
                    return responseData;
                })
            )
    }

    getInvoiceById(invoiceId: number){
        return this.http
            .get<Invoice>(this.apiUrl + 'invoices/invoiceId/' + invoiceId, {observe: "body", responseType: 'json'})
            .pipe(
                map((responseData: Invoice) => {
                    return responseData;
                }),
                catchError(this.handleError)
            )
    }

    printInvoice(invoiceHash: string){
        // @ts-ignore
        return this.http.get('../../../businessWeb/web/printInvoice/' + invoiceHash, {responseType: 'blob'})
            .pipe(
                map((file: Blob) => {
                    return file;
                }),
                catchError(this.handleError)
            )
    }

    updateInvoiceLineItemWithPackage(invoiceItem: InvoiceItem, packageSold: PackageSold){
        return this.http
            .put<{invoice: Invoice, packageSold: PackageSold}>(this.apiUrl + 'invoiceItem/' + invoiceItem.invoiceItemId + '/packageSold/' + packageSold.packageSoldId, {}, {observe: "body", responseType: "json"})
            .pipe(
                map((responseData: {invoice: Invoice, packageSold: PackageSold}) => {
                    return responseData;
                })
            )
    }

    createFormPost = function(paymentMap: PaymentMap){
        let form = document.createElement('form');
        form.action = paymentMap.postUrl;
        form.method = 'POST';
        // TT-6470: removing _blank from target for
        // form.target = '_blank';
        for(let key in paymentMap.map){
            let input = document.createElement('input');
            input.type = 'hidden';
            input.name = key;
            input.value = paymentMap.map[key];
            form.appendChild(input);
        }

        let submit = document.createElement('input');
        submit.type = 'submit';
        submit.id = 'submitPaymentPortal';

        form.appendChild(submit);
        document.body.appendChild(form);

        $('#submitPaymentPortal').click();
        document.body.removeChild(form);
    }

    createSafariFormPost = function(paymentMap: PaymentMap){
        let form = document.createElement('form');
        form.action = paymentMap.postUrl;
        form.method = 'POST';
        // TT-6470: removing target
        // form.target = '_top';
        for(let key in paymentMap.map){
            let input = document.createElement('input');
            input.type = 'hidden';
            input.name = key;
            input.value = paymentMap.map[key];
            form.appendChild(input);
        }

        let submit = document.createElement('input');
        submit.type = 'submit';
        submit.id = 'submitPaymentPortal';

        form.appendChild(submit);
        document.body.appendChild(form);

        $('#submitPaymentPortal').click();
        document.body.removeChild(form);
    }

    uploadFile(file: any, businessId: number){
        let fd = new FormData();
        fd.append('file', file);
        fd.append('businessId', businessId.toString());
        fd.append('uploadedFileName', file.name);
        fd.append('userName', 'WebClient');
        return this.http.post('../../../businessWeb/web/fileUploadS3.do', fd, {responseType: 'text', reportProgress: true})
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            )
    }

    checkInnerHTMLForHrefClick(event: MouseEvent): boolean { // For TT-6544 (05/04/2020) to make sure that when people click link in description it doesn't advance them
        let clickedHref = false;
        if(event !== undefined){
            let element = event.target as HTMLElement;
            if(element !== undefined && element.tagName === "A"){
                clickedHref = true;
            }
        }
        return clickedHref;
    }

    /***
     * Returns array of appointmentIntents based on business Id from request
     */
    getAppointmentIntents(clientId: number){
      return this.http
          .get<AppointmentIntent[]>(this.apiUrlSecure + 'appointmentIntentList/' + clientId, {observe: "body", responseType: 'json'})
          .pipe(
              map((responseData: AppointmentIntent[]) => {
                  return responseData;
              }),
              catchError(this.handleError)
          )
    }
    getAppointmentIntentById(appointmentIntentId: number){
        return this.http
            .get<AppointmentIntent>(this.apiUrlSecure + 'appointmentIntent/' + appointmentIntentId, {observe: "body", responseType: 'json'})
            .pipe(
                map((responseData: AppointmentIntent) => {
                    return responseData;
                }),
                catchError(this.handleError)
            )
    }

}
