import {AfterViewChecked, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CSFilterParameterBean, preference} from "../../../common-classes/app-objects.model";
import {ResponsiveService} from "../../../responsive.service";

@Component({
  selector: 'zip-code-filter',
  templateUrl: './zip-code-filter.component.html'
})
export class ZipCodeFilterComponent implements OnInit, AfterViewChecked {
  @Output() onSearchObjects = new EventEmitter<CSFilterParameterBean>();
  @Input() preference: preference;
  @Input() CSFilterObj: CSFilterParameterBean;
  @Input() filterMode: number;
  @Input() searching: boolean;
  filterValue: string;
  maxLength: string;

  constructor(private responsiveService: ResponsiveService) { }

  ngAfterViewChecked(): void {
    this.responsiveService.configureHorizontalSearch('filterSearchField', 'filterSearchBtn')
  }

  ngOnInit() {
    if(this.preference.filterMax && this.preference.filterMax > -1){
      this.maxLength = this.preference.filterMax + '';
    } else {
      this.maxLength = '';
    }
  }

  searchFilters(filterValue: string){
    this.CSFilterObj.filterMode = this.filterMode;
    switch (this.filterMode) {
      case 1:
        this.CSFilterObj.filterLocation = filterValue;
        break;
      case 2:
        this.CSFilterObj.filterStaff = filterValue;
        break;

    }
    this.onSearchObjects.emit(this.CSFilterObj);
  }
  checkEnter(event: KeyboardEvent, filterValue: string){
    if(event.key === 'Enter' && this.preference.filterMin > -1 && filterValue.length >= this.preference.filterMin){
      if(this.preference.booleanMap.allowMaxSearchChars || this.preference.booleanMap.allowMaxSearchChars===true){
        if(this.preference.filterMaxSearch && this.preference.filterMaxSearch > -1 && filterValue.length > this.preference.filterMaxSearch){
          filterValue = filterValue.substring(0, this.preference.filterMaxSearch);
        }
      }
      this.searchFilters(filterValue);
    }
  }

}
