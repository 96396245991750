export class SchedulerBooleanMapModel {
    autoAdvanceWhenSingle: boolean = true;
    autoProgressToPaymentPortal: boolean = true;
    defaultSMSRemindValue: boolean = false;
    timePnlAutoSelectTime: boolean = true;
    timePnlShowTimezones: boolean = false;
    showJoinWaitListTab: boolean = false;
    allowWaitListRegistrationOutsideOfAvailability: boolean = false;
    reasonDisplayBanner: boolean = false;
    businessInfoVisible: boolean = true;
    policyVisible: boolean = false;
    cancelReasonList: boolean = false;
    allowClientAddressValidation: boolean = false;
    appointmentIntentRequired: boolean; // Is Appointment Intent required?
    selectAppointmentIntent: boolean; // allow user to pick the Appointment Intent? (vs auto seleted)
    hidePanelResults: boolean;
    allowMaxSearchChars: boolean;
}
