import {Injectable, Injector} from "@angular/core";
import {DatePipe, getCurrencySymbol, TitleCasePipe} from "@angular/common";
import {
    Client,
    currency,
    preference, RecurringAppointment
} from "./common-classes/app-objects.model";
import {TimeService} from "./scheduler/time/time.service";
import * as moment from "moment/moment";
import * as momentTimezone from "moment-timezone/moment-timezone";
import {MomentTimezoneService} from "./scheduler/time/moment-timezones.service";
import * as _ from "lodash";
import {LabelMap} from "./common-classes/default-labels.model";
import {TableColumnDataFilterFormat, TableColumnDataModel} from "./common-classes/TableColumnData.model";
import {FormGroup} from "@angular/forms";
import {LocaleCurrency} from "../pipes/localecurrency.pipe";
import {BusinessService} from './business.service';
import {Group} from '../models/Group.model';
import {WaitList, WaitListDate, WaitListDateTime} from '../models/WaitList.model';
import {Appointment} from '../models/Appointment.model';
import {Reason, ReasonTypes} from '../models/Reason.model';
import {CustomField} from '../models/CustomField.model';
import {Location} from '../models/Location.model';
import {Staff} from '../models/Staff.model';
import {SelectedDateTimeObj} from "../models/SelectedDateTimeObj.model";
import {AppointmentIntent} from "../models/AppointmentIntent.model";


@Injectable()
export class TagsService {
    locale: string;
    businessService: BusinessService;
    constructor(private datePipe: DatePipe, private localeCurrency: LocaleCurrency, private titleCasePipe: TitleCasePipe,
                private timeService: TimeService, private momentTimezoneService: MomentTimezoneService, private injector: Injector){
        try {
            this.locale = sessionStorage.getItem('locale');
        } catch (e) {
            this.locale = 'en-US';
        }
        moment.locale(this.locale);
        momentTimezone.locale(this.locale);
        this.businessService = injector.get(BusinessService);
    }
    paymentButton: '<button mat-stroked-button class="btn btn-sm mat-stroked-button" id="payNowBtn">{{preference.labelMap.payNowButtonLabel}}</button>';
    apptIntentFieldTags: any[] = [
        {propName: "appointmentIntentId", tags: ["%APPT_INTENT_ID%"]},
        {propName: "externalReferenceNumber", tags: ["%APPT_INTENT_REFERENCE_NUM%"]},
        {propName: "description", tags: ["%APPT_INTENT_DESCRIPTION%"]},
        {propName: "startDate", tags: ["%APPT_INTENT_START_DATE%","%APPT_INTENT_START_DATE_TIME_LONG%"]},
        {propName: "endDate", tags: ["%APPT_INTENT_END_DATE%", "%APPT_INTENT_END_DATE_TIME_LONG%"]},
        {propName: "expirationDate", tags: ["%APPT_INTENT_EXPIRATION_DATE%"]}
    ];
    addOnReasonListTags: string[] = ['%ADD_ON_REASON_LIST_SIMPLE%',
        '%ADD_ON_REASON_LIST_PRICE%',
        '%ADD_ON_REASON_LIST_DESC%',
        '%ADD_ON_REASON_LIST_INSTRUCTIONS%',
        '%ADD_ON_REASON_LIST_INSTRUCTIONS2%',
        '%ADD_ON_REASON_LIST_PRICE_DESC%',
        '%ADD_ON_REASON_LIST_PRICE_INSTRUCTIONS%',
        '%ADD_ON_REASON_LIST_PRICE_INSTRUCTIONS2%']
    addOnReasonListTagLabelMap = [{labelMapProp: 'addOnReasonListItemLabelSimple', tag: '%ADD_ON_REASON_LIST_SIMPLE%'},
        {labelMapProp: 'addOnReasonListItemLabelPrice', tag: '%ADD_ON_REASON_LIST_PRICE%'},
        {labelMapProp: 'addOnReasonListItemLabelDesc', tag: '%ADD_ON_REASON_LIST_DESC%'},
        {labelMapProp: 'addOnReasonListItemLabelInstructions', tag: '%ADD_ON_REASON_LIST_INSTRUCTIONS%'},
        {labelMapProp: 'addOnReasonListItemLabelInstructions2', tag: '%ADD_ON_REASON_LIST_INSTRUCTIONS2%'},
        {labelMapProp: 'addOnReasonListItemLabelPriceDesc', tag: '%ADD_ON_REASON_LIST_PRICE_DESC%'},
        {labelMapProp: 'addOnReasonListItemLabelPriceInstructions', tag: '%ADD_ON_REASON_LIST_PRICE_INSTRUCTIONS%'},
        {labelMapProp: 'addOnReasonListItemLabelPriceInstructions2', tag: '%ADD_ON_REASON_LIST_PRICE_INSTRUCTIONS2%'}]
    businessTagsArray: any[] = [
        {propName: 'businessName', tags: ["%BUSINESS_NAME%"]}
    ]
    preferenceTagsArray: any[] = [
        {propName: 'maxActiveApptClient', tags: ["%MAX_ACTIVE_APPTS%"]},
        {propName: 'rescheduleHrs', tags: ["%ALLOWED_RESCHEDULE_HOURS%"]},
        {propName: 'cancelHrs', tags: ["%ALLOWED_CANCEL_HOURS%"]},
    ]
    clientTagsArray: any[] = [
        {propName: "fullName", tags: ["%CLIENT_FULLNAME%"]},
        {propName: "firstName", tags: ["%CLIENT_FIRSTNAME%"], fallBackProp: "fullName"},
        {propName: "activeApptCount", tags: ["%CLIENT_ACTIVE_APPT_COUNT%"]}
    ];
    locationFieldTags: any[] = [
        {propName: "locationName", tags: ["%LOCATION_NAME%"]},
        {propName: "description", tags: ["%LOCATION_ADDRESS%"]},
        {propName: "directions", tags: ["%LOCATION_DIRECTIONS%"]},
        {propName: "officePhone", tags: ["%LOCATION_OFFICEPHONE%"]},
        {propName: "emailAddress", tags: ["%LOCATION_EMAIL%"]},
        {propName: 'virtualRoomUrl', tags: ['%LOCATION_VIRTUAL_ROOM_URL%']}
    ];
    locationGroupFieldTags: any[] = [
        {propName: "groupName", tags: ["%LOCATION_GROUP_NAME%"]},
        {propName: "groupDescription", tags: ["%LOCATION_GROUP_DESCRIPTION%"]},
        {propName: "directions", tags: ["%LOCATION_GROUP_DIRECTIONS%"]},
        {propName: "privateUrl", tags: ["%LOCATION_GROUP_BOOKURL%"]}
    ];
    staffFieldTags: any[] = [
        {propName: "fullName", tags: ["%STAFF_FULLNAME%"]},
        {propName: "fullName", tags: ["%STAFF_FIRSTNAME%"]},
        {propName: "description", tags: ["%STAFF_DESCRIPTION%"]},
        {propName: "email", tags: ["%STAFF_EMAIL%"]},
        {propName: "skypeId", tags: ["%STAFF_SKYPE_ID%"]},
        {propName: "mobilePhone", tags: ["%STAFF_MOBILE%"]},
        {propName: "emailFooter", tags: ["%STAFF_SIGNATURE%"]},
        {propName: "emailInstructions", tags: ["%STAFF_INSTRUCTIONS%"]},
        {propName: "emailInstructions2", tags: ["%STAFF_INSTRUCTIONS2%"]},
        {propName: "zoomLink", tags: ["%STAFF_ZOOM_LINK%"]},
        {propName: "virtualRoomUrl", tags: ["%STAFF_VIRTUAL_ROOM_URL%"]},
        {propName: "virtualRoomId", tags: ["%STAFF_VIRTUAL_ROOM_ID%"]}
    ];
    reasonFieldTags: any[] = [
        {propName: 'reasonDesc', tags: ['%REASON%']},
        {propName: 'reasonDetail', tags: ['%REASON_DESCRIPTION%']},
        {propName: 'price', tags: ['%REASON_PRICE%']},
        {propName: 'emailInstructions', tags: ['%REASON_INSTRUCTIONS%']},
        {propName: 'emailInstructions2', tags: ['%REASON_INSTRUCTIONS2%']},
        {propName: 'internalName', tags: ['%REASON_INTERNAL%']}
    ];
    reasonGroupFieldTags: any[] = [
        {propName: "groupName", tags: ["%REASON_GROUP_NAME%"]},
        {propName: "groupDescription", tags: ["%LOCATION_GROUP_DESCRIPTION%"]},
        {propName: "emailInstructions", tags: ["%REASON_GROUP_EMAIL_INSTRUCTIONS%"]},
    ];
    repeatingApptTags: any[] = [
        {propName: 'recurringAppointmentId', tags: ['%RECURRING_APPT_ID%']},
        {propName: 'status', tags: ['%RECURRING_STATUS%']},
        {propName: 'startDate', tags: ['%RECURRING_APPT_STARTDATE%']},
        {propName: 'endDate', tags: ['%RECURRING_APPT_ENDDATE%']},
        {propName:'weeksOrMonths', tags: ['%REPEAT_PERIOD%']},
        {propName:'frequency', tags: ['%REPEAT_FREQUENCY%']}
    ]
    repeatingApptOptionTags: any[] = [
        {propName: 'numberOfAppointments', tags: ['%REASON_REPEATING_OPTION_SESSION_COUNT%']}
    ]
    waitlistRegistrationTags: any[] = [
        {propName: 'waitListId', tags: ['%WAIT_LIST_ID%']},
        {propName: 'status', tags: ['%WAIT_LIST_STATUS%']},
        {propName: 'preferredDate', tags: ['%WAIT_LIST_PREFERRED_DATE%']},
        {propName: 'preferredDateStart', tags: ['%WAIT_LIST_PREFERRED_DATE_START%']},
        {propName: 'preferredDateEnd', tags: ['%WAIT_LIST_PREFERRED_DATE_END%']},
    ]
    invoiceFieldTags: any[] =[
        {propName: 'balanceAmount', tags: ['%INVOICE_BALANCE_AMOUNT%']},
        {propName: 'invoiceNumber', tags: ['%INVOICE_NUMBER%']},
        {propName: 'subtotalAmount', tags: ['%INVOICE_SUBTOTAL_AMOUNT%']},
        {propName: 'taxAmount', tags: ['%INVOICE_TAX_AMOUNT%']},
        {propName: 'tax2Amount', tags: ['%INVOICE_TAX2_AMOUNT%']},
        {propName: 'invoiceTotalAmount', tags: ['%INVOICE_AMOUNT%']},
        {propName: 'paidAmount', tags: ['%INVOICE_PAID_AMOUNT%']},
        {propName: 'paymentAmount', tags: ['%INVOICE_PAYMENT_AMOUNT%']},
    ];
    invoiceItemFieldTags: any[] = [
        {propName: 'description', tags: ['%INVOICE_ITEM_DESCRIPTION%']}
    ]
    creditCardFieldTags: any[] = [
        {propName: 'brand', tags: ['%CREDIT_CARD_BRAND%']},
        {propName: 'last4', tags: ['%CREDIT_CARD_LAST4%']},
        {propName: 'expiration', tags: ['%CREDIT_CARD_EXPIRATION%']},
        {propName: 'zip', tags: ['%CREDIT_CARD_ZIP%']},
    ]
    packageFieldTags: any[] = [
        {propName: 'packageName', tags: ['%PACKAGE_NAME%']},
    ]
    packageSoldFieldTags: any[] = [
        {propName: 'usedQuantity', tags: ['%PACKAGE_USED_QUANTITY%']},
        {propName: 'remainingQuantity', tags: ['%PACKAGE_REMAINING_QUANTITY%']},
        {propName: 'maxQuantity', tags: ['%PACKAGE_MAX_QUANTITY%']},
        {propName: 'expireDate', tags: ['%PACKAGE_EXPIRE_DATE%']},
        {propName: 'status', tags: ['%PACKAGE_STATUS%']}
    ]
    couponFieldTags: any[] = [
        {propName: 'couponCode', tags: ['%COUPON_CODE%']},
        {propName: 'couponName', tags: ['%COUPON_NAME%']},
        {propName: 'description', tags: ['%COUPON_DESCRIPTION%']},
        {propName: 'endDate', tags: ['%COUPON_ENDDATE%']},
        {propName: 'maxUseCount', tags: ['%COUPON_MAX_USE_COUNT%']},
        {propName: 'appliedReasonCount', tags: ['%COUPON_APPLIED_REASON_COUNT%']},
    ]
    disclaimerFormTags: any[] = [
        {propName: 'formName', tags: ['%FORM_NAME%']},
    ]
    clientFormListObjTags: any[] = [
        {propName: 'formName', tags: ['%FORM_NAME%']},
        {propName: 'disclaimerFormDataUrl', tags: ['%FORM_URL%']},
        {propName: 'disclaimerFormUuid', tags: ['%FORM_UUID%']},
    ];
    apptFieldTags: any[] = [
        {propName: 'appointmentDateTimeClient', tags: ['%APPT_DATE_TIME%']},
        {propName: 'appointmentDateTimeStaff', tags: ['%APPT_DATE_TIME_STAFF%']},
        {propName: 'status', tags: ['%APPT_STATUS%'], filter: 'titleCase'},
        {propName: 'subStatus', tags: ['%APPT_SUBSTATUS%'], filter: 'titleCase'},
        // {propName: 'clientStartDate', tags: ['%APPT_DATE%', '%APPT_CLIENT_START_DATE_FULL%','%APPT_CLIENT_START_DATE_LONG','%APPT_CLIENT_START_DATE_MEDIUM%','%APPT_CLIENT_START_DATE_SHORT%']},
        // {propName: 'clientEndDate', tags: ['%APPT_END_DATE%','%APPT_CLIENT_END_DATE_FULL%','%APPT_CLIENT_END_DATE_LONG%','%APPT_CLIENT_END_DATE_MEDIUM%','%APPT_CLIENT_END_DATE_SHORT%']},
        // {propName: 'clientStartTime', tags: ['%APPT_START_TIME%','%APPT_CLIENT_START_TIME_FULL%','%APPT_CLIENT_START_TIME_MEDIUM%','%APPT_CLIENT_START_TIME_SHORT%']},
        // {propName: 'clientEndTime', tags: ['%APPT_END_TIME%','%APPT_CLIENT_END_TIME_FULL%','%APPT_CLIENT_END_TIME_MEDIUM%','%APPT_CLIENT_END_TIME_SHORT%']},
        {propName: 'startTime', tags: ['%APPT_START_TIME_STAFF%']},
        {propName: 'endTime', tags: ['%APPT_END_TIME_STAFF%']},
        {propName: 'clientStartTimeDate', tags: ['%APPT_CLIENT_START_DATE_TIME_FULL%','%APPT_CLIENT_START_DATE_TIME_LONG%','%APPT_CLIENT_START_DATE_TIME_MEDIUM%',
                '%APPT_DATE%', '%APPT_CLIENT_START_DATE_FULL%','%APPT_CLIENT_START_DATE_LONG%','%APPT_CLIENT_START_DATE_MEDIUM%','%APPT_CLIENT_START_DATE_SHORT%','%APPT_CLIENT_START_DATE_TIME_SHORT%',
                '%APPT_START_TIME%','%APPT_CLIENT_START_TIME_FULL%','%APPT_CLIENT_START_TIME_MEDIUM%','%APPT_CLIENT_START_TIME_SHORT%','%TIMEZONE_SHORT%'
                ]},
        {propName: 'clientEndTimeDate', tags: ['%APPT_CLIENT_END_DATE_TIME_FULL%','%APPT_CLIENT_END_DATE_TIME_LONG%','%APPT_CLIENT_END_DATE_TIME_MEDIUM%','%APPT_CLIENT_END_DATE_TIME_SHORT%',
                '%APPT_END_DATE%','%APPT_CLIENT_END_DATE_FULL%','%APPT_CLIENT_END_DATE_LONG%','%APPT_CLIENT_END_DATE_MEDIUM%','%APPT_CLIENT_END_DATE_SHORT%',
                '%APPT_END_TIME%','%APPT_CLIENT_END_TIME_FULL%','%APPT_CLIENT_END_TIME_MEDIUM%','%APPT_CLIENT_END_TIME_SHORT%'
                ]},
        {propName: 'staffStartTimeDate', tags: ['%APPT_STAFF_START_DATE_TIME_FULL%','%APPT_STAFF_START_DATE_TIME_LONG%','%APPT_STAFF_START_DATE_TIME_MEDIUM%',
                '%APPT_STAFF_START_DATE_FULL%','%APPT_STAFF_START_DATE_LONG%','%APPT_STAFF_START_DATE_MEDIUM%','%APPT_STAFF_START_DATE_SHORT%',
                '%APPT_START_TIME_STAFF%','%APPT_STAFF_START_TIME_FULL%','%APPT_STAFF_START_TIME_MEDIUM%','%APPT_STAFF_START_TIME_SHORT%', '%APPT_STAFF_START_DATE_TIME_SHORT%'
            ]},
        {propName: 'staffEndTimeDate', tags: ['%APPT_STAFF_END_DATE_TIME_FULL%','%APPT_STAFF_END_DATE_TIME_LONG%','%APPT_STAFF_END_DATE_TIME_MEDIUM%','%APPT_STAFF_END_DATE_TIME_SHORT%',
                '%APPT_STAFF_END_DATE_FULL%','%APPT_STAFF_END_DATE_LONG%','%APPT_STAFF_END_DATE_MEDIUM%','%APPT_STAFF_END_DATE_SHORT%',
                '%APPT_END_TIME_STAFF%','%APPT_STAFF_END_TIME_FULL%','%APPT_STAFF_END_TIME_MEDIUM%','%APPT_STAFF_END_TIME_SHORT%'
            ]},
        {propName: 'calendarId', tags: ['%APPT_ID%']},
        {propName: 'classScheduleId', tags: ['%CLASS_SESSION_ID%']},
        {propName: 'price', tags: ['%APPT_AMOUNT%']},
        {propName: 'classroom', tags: ['%CLASSROOM%','%CLASS_ROOM%']},
        {propName: 'seats', tags: ['%APPT_SEATS%']},
        {propName: 'note', tags: ['%APPOINTMENT_NOTE%']},
        {propName: 'address', tags: ['%APPT_ADDRESS%']},
        {propName: 'changeSummaryClient', tags: ['%CHANGE_SUMMARY_CLIENT%']},
        {propName: 'changeSummaryStaff', tags: ['%CHANGE_SUMMARY_STAFF%']},
        {propName: 'customFieldDesc', tags: ['%CUSTOM_FIELDS%'], filter: 'lineBreak'},
        {propName: 'reasonDesc', tags: ['%APPT_REASON_DESC%']},
        {propName: 'hashId', tags: ['%MANAGE_APPT_URL%']},
        {propName: 'apptQRCode', tags: ['%APPT_QR_CODE%']},
        {propName: 'apptCheckInQRCode', tags: ['%APPT_CHECKIN_QR_CODE%']},
        {propName: 'apptDurationMinutesClient', tags: ['%APPT_DURATION_MINUTES_CLIENT%']}

    ];

    dateTimeTagMap: any[] = [
        {'tags': ['%APPT_INTENT_START_DATE%', '%APPT_INTENT_END_DATE%', '%APPT_INTENT_EXPIRATION_DATE%'], 'filterFormats': ['L']},
        {'tags': ['%APPT_CLIENT_START_DATE_TIME_FULL%','%APPT_CLIENT_END_DATE_TIME_FULL%','%APPT_STAFF_START_DATE_TIME_FULL%','%APPT_STAFF_END_DATE_TIME_FULL%'], 'filterFormats': ['LLLL']},
        {'tags': ['%TIMEZONE_SHORT%'], 'filterFormats': ['z']},
        {'tags': ['%APPT_CLIENT_START_DATE_TIME_LONG%','%APPT_CLIENT_END_DATE_TIME_LONG%','%APPT_STAFF_START_DATE_TIME_LONG%','%APPT_STAFF_END_DATE_TIME_LONG%'], 'filterFormats': ['LL', 'LTS']},
        {'tags': ['%APPT_CLIENT_START_DATE_TIME_MEDIUM%','%APPT_CLIENT_END_DATE_TIME_MEDIUM%','%APPT_STAFF_START_DATE_TIME_MEDIUM%','%APPT_STAFF_END_DATE_TIME_MEDIUM%'], 'filterFormats': ['LL', 'LTS']},
        {'tags': ['%APPT_CLIENT_START_DATE_TIME_SHORT%','%APPT_CLIENT_END_DATE_TIME_SHORT%','%APPT_STAFF_START_DATE_TIME_SHORT%','%APPT_STAFF_END_DATE_TIME_SHORT%'], 'filterFormats': ['L', 'LT']},
        {'tags': ['%APPT_CLIENT_START_DATE_FULL%','%APPT_CLIENT_END_DATE_FULL%','%APPT_STAFF_START_DATE_FULL%','%APPT_STAFF_END_DATE_FULL%'], 'filterFormats': ['dddd, LL ']},
        {'tags': ['%APPT_CLIENT_START_DATE_LONG%','%APPT_CLIENT_END_DATE_LONG%','%APPT_STAFF_START_DATE_LONG%','%APPT_STAFF_END_DATE_LONG%'], 'filterFormats': ['LL']},
        {'tags': ['%APPT_CLIENT_START_DATE_MEDIUM%','%APPT_CLIENT_END_DATE_MEDIUM%','%APPT_STAFF_START_DATE_MEDIUM%','%APPT_STAFF_END_DATE_MEDIUM%'], 'filterFormats': ['LL']},
        {'tags': ['%APPT_DATE%','%APPT_END_DATE%','%APPT_CLIENT_START_DATE_SHORT%','%APPT_CLIENT_END_DATE_SHORT%','%APPT_STAFF_START_DATE_SHORT%','%APPT_STAFF_END_DATE_SHORT%','%COUPON_ENDDATE%'], 'filterFormats': ['L']},
        {'tags': ['%APPT_CLIENT_START_TIME_FULL%','%APPT_CLIENT_START_TIME_MEDIUM%','%APPT_CLIENT_END_TIME_FULL%','%APPT_CLIENT_END_TIME_MEDIUM%','%APPT_STAFF_START_TIME_FULL%','%APPT_STAFF_START_TIME_MEDIUM%','%APPT_STAFF_END_TIME_FULL%','%APPT_STAFF_END_TIME_MEDIUM%'], 'filterFormats': ['LTS']},
        {'tags': ['%APPT_START_TIME%','%APPT_END_TIME%','%APPT_CLIENT_START_TIME_SHORT%','%APPT_CLIENT_END_TIME_SHORT%','%APPT_START_TIME_STAFF%','%APPT_END_TIME_STAFF%','%APPT_STAFF_START_TIME_SHORT%','%APPT_STAFF_END_TIME_SHORT%'], 'filterFormats': ['LT']},
    ];
    dateTimeTags: any[] = ['%APPT_INTENT_START_DATE%', '%APPT_INTENT_END_DATE%', '%APPT_INTENT_EXPIRATION_DATE%', '%APPT_CLIENT_START_DATE_TIME_FULL%','%APPT_CLIENT_START_DATE_TIME_LONG%','%APPT_CLIENT_START_DATE_TIME_MEDIUM%','%APPT_STAFF_START_DATE_TIME_SHORT%',
        '%APPT_CLIENT_END_DATE_TIME_FULL%','%APPT_CLIENT_END_DATE_TIME_LONG%','%APPT_CLIENT_END_DATE_TIME_MEDIUM%','%APPT_STAFF_END_DATE_TIME_SHORT%',
        '%APPT_DATE%', '%APPT_CLIENT_START_DATE_FULL%','%APPT_CLIENT_START_DATE_LONG%','%APPT_CLIENT_START_DATE_MEDIUM%','%APPT_CLIENT_START_DATE_SHORT%',
        '%APPT_END_DATE%','%APPT_CLIENT_END_DATE_FULL%','%APPT_CLIENT_END_DATE_LONG%','%APPT_CLIENT_END_DATE_MEDIUM%','%APPT_CLIENT_END_DATE_SHORT%',
        '%APPT_START_TIME%','%APPT_CLIENT_START_TIME_FULL%','%APPT_CLIENT_START_TIME_MEDIUM%','%APPT_CLIENT_START_TIME_SHORT%',
        '%APPT_END_TIME%','%APPT_CLIENT_END_TIME_FULL%','%APPT_CLIENT_END_TIME_MEDIUM%','%APPT_CLIENT_END_TIME_SHORT%','%COUPON_ENDDATE%','%PACKAGE_EXPIRE_DATE%', '%TIMEZONE_SHORT%'
    ];
    timeTags: any[] = ['%APPT_CLIENT_START_DATE_TIME_LONG%','%APPT_CLIENT_START_DATE_TIME_MEDIUM%','%APPT_STAFF_START_DATE_TIME_SHORT%',
        '%APPT_CLIENT_END_DATE_TIME_FULL%','%APPT_CLIENT_END_DATE_TIME_LONG%','%APPT_CLIENT_END_DATE_TIME_MEDIUM%','%APPT_STAFF_END_DATE_TIME_SHORT%',
        '%APPT_DATE%', '%APPT_CLIENT_START_DATE_FULL%','%APPT_CLIENT_START_DATE_LONG%','%APPT_CLIENT_START_DATE_MEDIUM%','%APPT_CLIENT_START_DATE_SHORT%',
        '%APPT_END_DATE%','%APPT_CLIENT_END_DATE_FULL%','%APPT_CLIENT_END_DATE_LONG%','%APPT_CLIENT_END_DATE_MEDIUM%','%APPT_CLIENT_END_DATE_SHORT%',
        '%APPT_START_TIME%','%APPT_CLIENT_START_TIME_FULL%','%APPT_CLIENT_START_TIME_MEDIUM%','%APPT_CLIENT_START_TIME_SHORT%',
        '%APPT_END_TIME%','%APPT_CLIENT_END_TIME_FULL%','%APPT_CLIENT_END_TIME_MEDIUM%','%APPT_CLIENT_END_TIME_SHORT%','%COUPON_ENDDATE%','%PACKAGE_EXPIRE_DATE%', '%TIMEZONE_SHORT%'];
    currencyTags: any[] = ['%INVOICE_BALANCE_AMOUNT%','%INVOICE_PAYMENT_AMOUNT%','%INVOICE_SUBTOTAL_AMOUNT%','%INVOICE_TAX_AMOUNT%','%INVOICE_TAX2_AMOUNT%','%INVOICE_AMOUNT%','%INVOICE_PAID_AMOUNT%','%APPT_AMOUNT%','%REASON_PRICE%'];
    appointmentIntentFieldTags: any[] = [
      {propName: 'startDate', tags: ["%APPT_INTENT_START_DATE%",'%APPT_INTENT_START_DATE_TIME_LONG%']},
      {propName: 'endDate', tags: ["%APPT_INTENT_END_DATE%",'%APPT_INTENT_END_DATE_TIME_LONG%']},
      {propName: 'externalReferenceNumber', tags: ['%EXTERNAL_REFERENCE_NUMBER%']},
    ];


    timeFormatting(time: any){
        if(time !== undefined && time !== null){
            let returnString = time.toString();
            let hour: any, minutes: any;
            if(returnString.length===3){
                hour = returnString.substring(0,1);
                minutes = returnString.substring(1,3);
            } else if (returnString.length === 1){
                hour = "00";
                minutes = "0" + returnString;
            } else if(returnString.length === 2){
                //if it's 2, then it's going to be 12 am
                hour = "00";
                minutes = returnString;
            } else {
                hour = returnString.substring(0,2);
                minutes = returnString.substring(2,4);
            }

            if(hour !== "00"){
                hour = parseInt(hour);
            }

            let fullDate = new Date();
            let newDate = new Date(fullDate.getFullYear(), fullDate.getMonth(), fullDate.getDay(), hour, minutes);

            returnString = this.datePipe.transform(newDate, 'hh:mm a', sessionStorage.getItem("timezone"), sessionStorage.getItem('locale'));
            return returnString;
        } else {
            return time;
        }
    }

    convertSessionTZFromMoment(timeZoneCode: string){
        let timeZoneForTimeZoneFilter = timeZoneCode;
        for(let i = 0, x = this.momentTimezoneService.allTimezones.links.length; i < x; i++){
            if(this.momentTimezoneService.allTimezones.links[i].indexOf(timeZoneCode) !== -1){
                let indexOfPipe = this.momentTimezoneService.allTimezones.links[i].indexOf("|");
                timeZoneForTimeZoneFilter = this.momentTimezoneService.allTimezones.links[i].substring(indexOfPipe + 1);
            }
        }
        return timeZoneForTimeZoneFilter;
    }



    guessClientsTimezone(){
        return momentTimezone.tz.guess();
    }

    getMomentFormatString(timePropValue: any, timeZoneForMoment: string, filterFormat: string){
        if(filterFormat === 'z'){
            return momentTimezone(timePropValue).tz(timeZoneForMoment).zoneAbbr();
        } else {
            const timeToReturn = momentTimezone(timePropValue).tz(timeZoneForMoment).format(filterFormat);
            return timeToReturn;
        }

    }

    assignObjectToTags = function (calendarObj: any, stringWithTag: string, tags: any, currency?: currency, allowTZChange?: number) {
        if (calendarObj !== undefined && calendarObj !== null) {
            if (stringWithTag !== null) {
                let tz = '';
                if(calendarObj.appointmentDateTimeClient && stringWithTag.indexOf('%APPT_DATE_TIME%') !== -1){
                    tz = calendarObj.appointmentDateTimeClient.substring(calendarObj.appointmentDateTimeClient.length - 3, calendarObj.appointmentDateTimeClient.length);
                    if(tz === ' AM' || tz === ' PM')
                        tz = '';
                    else
                        tz = ' ' + tz;
                    stringWithTag = stringWithTag.replace(new RegExp('%APPT_DATE_TIME%', 'g'), '%APPT_CLIENT_START_DATE_TIME_FULL%' + tz);
                }
            }
            for (let prop in calendarObj) {
                for (let i = 0, x = tags.length; i < x; i++) {
                    if (prop === tags[i].propName) {
                        for (let j = 0, y = tags[i].tags.length; j < y; j++) {
                            let valueToReplace = '';
                            if (calendarObj[prop] !== null) {
                                if(prop === 'virtualRoomUrl')
                                    console.log(calendarObj[prop] + ' : ' + stringWithTag);
                                valueToReplace = calendarObj[prop];
                                if(tags[i].filter === 'titleCase')
                                    valueToReplace = this.titleCasePipe.transform(valueToReplace);
                                if(tags[i].filter === 'lineBreak')
                                    valueToReplace = valueToReplace.replace(new RegExp(/\n/, 'g'), "<br/>");
                            } else if(calendarObj[prop] === null && tags[i].fallBackProp !== undefined && calendarObj[tags[i].fallBackProp] !== null){
                                valueToReplace = calendarObj[tags[i].fallBackProp];
                            } else if(calendarObj[prop] === null){
                                valueToReplace = '';
                            }
                            if(tags[i].tags[j] === '%MANAGE_APPT_URL%'){
                                let newUrl = window.location.href.substring(0, window.location.href.indexOf('#'));
                                valueToReplace = newUrl + '#/appts/' + calendarObj[prop];
                            }
                            if(tags[i].tags[j] === '%MANAGE_APPT_URL%'){
                                let newUrl = window.location.href.substring(0, window.location.href.indexOf('#'));
                                valueToReplace = newUrl + '#/appts/' + calendarObj[prop];
                            }
                            if (this.dateTimeTags.indexOf(tags[i].tags[j]) !== -1) {
                                let timezone = this.timeService.getApptTimezone(calendarObj.location, calendarObj.locationGroup, calendarObj.staff); // LHB 10/2/2020 TT-7052
                                if (allowTZChange === 1) {
                                    if (calendarObj.client && calendarObj.client.timeZoneCode) // LHB 11/10/2020 TT-7153
                                        timezone = calendarObj.client.timeZoneCode.timeZoneCode;
                                    else if (this.timeService.clientTimezone) // LHB 10/16/2020 TT-7087
                                        timezone = this.timeService.clientTimezone;
                                }
                                let timeZoneForMoment = this.timeService.checkToAddMomentTimezoneDefaultSchedulerPreference(timezone);
                                for (let k = 0, z = this.dateTimeTagMap.length; k < z; k++) {
                                    if (this.dateTimeTagMap[k].tags.indexOf(tags[i].tags[j]) !== -1) {
                                        for (let l = 0, a = this.dateTimeTagMap[k].filterFormats.length; l < a; l++) {
                                            if (l === 0) {
                                               // valueToReplace = momentTimezone(calendarObj[prop]).tz(timeZoneForMoment).format(this.dateTimeTagMap[k].filterFormats[l]);
                                                valueToReplace = this.getMomentFormatString(calendarObj[prop], timeZoneForMoment, this.dateTimeTagMap[k].filterFormats[l]);
                                            } else {
                                                // valueToReplace = valueToReplace + " " + momentTimezone(calendarObj[prop]).tz(timeZoneForMoment).format(this.dateTimeTagMap[k].filterFormats[l]);
                                                valueToReplace = valueToReplace + " " + this.getMomentFormatString(calendarObj[prop], timeZoneForMoment, this.dateTimeTagMap[k].filterFormats[l]);
                                            }
                                        }
                                    }
                                }
                            }
                            
                            if (this.currencyTags.indexOf(tags[i].tags[j]) !== -1 && currency !== undefined) {
                                valueToReplace = this.localeCurrency.transform(valueToReplace, currency);
                                let currencySymbol = getCurrencySymbol(currency.currencyCode, "wide");
                                //TT-6463 -- to fix, need to make sure the $ sign that is added to the valueToReplace is added right before current dollar sign
                                let indexOfCurrencySymobol = valueToReplace.indexOf(currencySymbol);
                                let currencySymbolPrefix = valueToReplace.substring(indexOfCurrencySymobol, currencySymbol.length);
                                if(currencySymbolPrefix === '$') { // LHB 08/26/2020 TT-6928 when it is a euro as a prefix no need to adjust with $ sign
                                    let valueSuffix = valueToReplace.substring(currencySymbol.length);
                                    valueToReplace = currencySymbolPrefix + '$' + valueSuffix;//TT-6120 -- use $ as escape character in regex https://mattsnider.com/safari-regex-issue-with-0-9-in-replacement-text/
                                //TT-11777: GBP and EUR are not displaying correctly with the default encoding in production
                                } else if (currency.currencyCode === 'GBP') {
                                    let valueSuffix = valueToReplace.substring(currencySymbol.length);
                                    valueToReplace =  '\u00A3' + valueSuffix;
                                } else if (currency.currencyCode === 'EUR') {
                                let valueSuffix = valueToReplace.substring(currencySymbol.length);
                                valueToReplace = '\u20AC' + valueSuffix;
                            }
                            }
                            if(stringWithTag !== null) {

                                stringWithTag = stringWithTag.replace(new RegExp(tags[i].tags[j], 'g'), valueToReplace);
                            }
                        }

                    }
                }
            }
        } else if(stringWithTag !== null) {
            for (let i = 0, x = tags.length; i < x; i++) {
                for(let j = 0, y = tags[i].tags.length; j < y; j++){
                    let tagToRemove = tags[i].tags[j];
                    //Need to clean up booking summaries so that if location and/or staff are not included in the flow that "with" and "at" don't hang in the string
                    if(tagToRemove === '%LOCATION_NAME%' && stringWithTag.toUpperCase().indexOf('AT %LOCATION_NAME%') !== -1){
                        tagToRemove = 'AT ' + tagToRemove;
                    }
                    if((tagToRemove === '%STAFF_FULLNAME%' && stringWithTag.toUpperCase().indexOf('WITH %STAFF_FULLNAME%') !== -1) ||
                        (tagToRemove === '%STAFF_FIRSTNAME%' && stringWithTag.toUpperCase().indexOf('WITH %STAFF_FIRSTNAME%') !== -1)){
                        tagToRemove = 'WITH ' + tagToRemove;
                    }
                    stringWithTag = stringWithTag.replace(new RegExp(tagToRemove, 'gi'), '');
                }
            }
        }
        return stringWithTag;
    };

    getStringFromCommonObjectProperties = function(stringPropertyToConvert: string, preference: preference, location: Location, staff: Staff, reason: Reason, client: Client, locationGroup: Group, reasonGroup: Group, intent: AppointmentIntent){
        let stringToDisplay = this.assignObjectToTags(location, preference.labelMap[stringPropertyToConvert], this.locationFieldTags);
        stringToDisplay = this.assignObjectToTags(staff, stringToDisplay, this.staffFieldTags);
        stringToDisplay = this.assignObjectToTags(reason, stringToDisplay, this.reasonFieldTags);
        stringToDisplay = this.assignObjectToTags(client, stringToDisplay, this.clientTagsArray);
        stringToDisplay = this.assignObjectToTags(locationGroup, stringToDisplay, this.locationGroupFieldTags);
        stringToDisplay = this.assignObjectToTags(reasonGroup, stringToDisplay, this.reasonGroupFieldTags);
        console.log('assigning intent values');
        stringToDisplay = this.assignObjectToTags(intent, stringToDisplay, this.apptIntentFieldTags);
        return stringToDisplay
    }

    assignFieldsValuesToTags(stringWithTag: string, fields: CustomField[]){
        if(fields !== undefined && fields !== null) {
            for (let i = 0, x = fields.length; i < x; i++) {
                if(stringWithTag.indexOf(fields[i].valueTag) !== -1 && fields[i].value !== null) {
                    stringWithTag = stringWithTag.replace(new RegExp(fields[i].valueTag, 'g'), fields[i].value);
                }
                if(stringWithTag.indexOf(fields[i].labelTag) !== -1 && fields[i].label !== null) {
                    stringWithTag = stringWithTag.replace(new RegExp(fields[i].labelTag, 'g'), fields[i].label);
                }
            }
        }
        return stringWithTag;
    }

    removeNonConvertedTags(stringWithTag: string, fields: CustomField[]){
        let fieldsAnyType: any[] = fields;
        if(fields !== undefined && fields !== null) {
            for (let i = 0, x = fields.length; i < x; i++) {
                if (stringWithTag.indexOf(fields[i].valueTag) !== -1 && fields[i].value === null) {
                    stringWithTag = stringWithTag.replace(new RegExp(fields[i].valueTag, 'g'), '');
                }
                if(stringWithTag.indexOf(fields[i].labelTag) !== -1 && fields[i].label === null) {
                    stringWithTag = stringWithTag.replace(new RegExp(fields[i].labelTag, 'g'), '');
                }
                if(fieldsAnyType[i].tags && stringWithTag.indexOf(fieldsAnyType[i].tags[0]) !== -1) {
                    stringWithTag = stringWithTag.replace(new RegExp(fieldsAnyType[i].tags[0], 'g'), '');
                }
            }
        }
        return stringWithTag;
    }

    convertApptPropertiesToTags(stringPropertyToConvert: string, preference: preference, appt: Appointment){
        let stringToDisplay: string;
        stringToDisplay = this.getStringFromCommonObjectProperties(stringPropertyToConvert, preference, appt.location, appt.staff, appt.reason, appt.client, appt.locationGroup, appt.reasonGroup, appt.appointmentIntent);
        stringToDisplay = this.assignFieldsValuesToTags(stringToDisplay, appt.fields);
        if(appt.client !== undefined && appt.client !== null)
            stringToDisplay = this.assignFieldsValuesToTags(stringToDisplay, appt.client.fields);
        appt = this.timeService.getApptDateTimeDisplayPropertiesWithTimezone(appt, preference.allowTZChange);
        stringToDisplay = this.assignObjectToTags(appt, stringToDisplay, this.apptFieldTags, undefined, preference.allowTZChange);
        if ( this.businessService.business)
            stringToDisplay = this.assignObjectToTags(this.businessService.business, stringToDisplay, this.businessTagsArray);
        if (stringToDisplay)
            for (let i = 0, x = this.addOnReasonListTags.length; i < x; i++) {
                const tag = this.addOnReasonListTags[i];
                if (stringToDisplay.indexOf(tag) !== -1) {
                    let prop = 'addOnReasonListItemLabelSimple';
                    for (let j = 0, y = this.addOnReasonListTagLabelMap.length; j < y; j++) {
                        const mapTag = this.addOnReasonListTagLabelMap[j].tag
                        if (mapTag === tag)
                            prop = this.addOnReasonListTagLabelMap[j].labelMapProp;
                    }

                    let stringToAdd = '';
                    if (appt.addOnReasonList && appt.addOnReasonList.length > 0) {
                        stringToAdd = '<ul>';
                        for(let j = 0, y = appt.addOnReasonList.length; j < y; j++){
                            stringToAdd = stringToAdd + '<li>' + this.assignObjectToTags(appt.addOnReasonList[j], preference.labelMap[prop], this.reasonFieldTags, this.businessService.currency) + '</li>';
                        }
                        stringToAdd = stringToAdd + '</ul>';
                    }
                    stringToDisplay = stringToDisplay.replace(new RegExp(tag, 'g'), stringToAdd);
                }
            }

        stringToDisplay = this.removeNonConvertedTags(stringToDisplay, appt.fields);
        if(appt.client !== undefined && appt.client !== null) {
            stringToDisplay = this.removeNonConvertedTags(stringToDisplay, appt.client.fields);
        }
        if (stringToDisplay) {
            stringToDisplay = this.removeNonConvertedTags(stringToDisplay, this.staffFieldTags);
            stringToDisplay = this.removeNonConvertedTags(stringToDisplay, this.locationFieldTags);
        }


        return stringToDisplay;
    };

    convertRepeatingApptPropertiesToTags = function(stringPropertyToConvert: string, preference: preference, recurringAppt: RecurringAppointment, appointments: Appointment[]){
        let stringToDisplay = this.getStringFromCommonObjectProperties(stringPropertyToConvert, preference, recurringAppt.location, recurringAppt.staff, recurringAppt.reason, recurringAppt.client, recurringAppt.locationGroup, recurringAppt.reasonGroup);
        //BEFORE WE CAN TRANSFORM ANY OF THE DATE AND TIME TAGS, NEED TO FIRST FORMAT AS DATE OBJECT;
        if(typeof recurringAppt.startDate === 'string') {
            if(recurringAppt.clientStartTime === null || recurringAppt.clientStartTime === undefined){
                recurringAppt.clientStartTime = 0;
            }
            recurringAppt.startTimeDate = this.timeService.convertStringDateToObject(recurringAppt.startDate, recurringAppt.clientStartTime);
        }
        if(typeof recurringAppt.endDate === 'string') {
            if(recurringAppt.clientEndTime === null || recurringAppt.clientEndTime === undefined){
                recurringAppt.clientEndTime = 0;
            }
            recurringAppt.endTimeDate = this.timeService.convertStringDateToObject(recurringAppt.endDate, recurringAppt.clientEndTime);
        }
        stringToDisplay = this.assignObjectToTags(recurringAppt, stringToDisplay, this.apptFieldTags);

        //TT-9386: Short term fix to have multiple appointments display correctly
        let tagList = ['%APPT_CLIENT_START_DATE_TIME_FULL%', '%APPT_CLIENT_START_DATE_TIME_LONG%', '%APPT_CLIENT_START_DATE_TIME_MEDIUM%',
            '%APPT_CLIENT_START_DATE_TIME_SHORT%', '%APPT_DATE_TIME%', '%APPT_START_TIME%'];
        if(stringToDisplay.indexOf('%REPEATING_APPT_DATE_LIST%')!==-1 && appointments.length !== 0){
            let stringToAdd = '<ul>';
            for(let i = 0, x = appointments.length; i < x; i++){
                stringToAdd = stringToAdd + '<li>' + this.convertApptPropertiesToTags('repeatingApptDateList', preference, appointments[i]) + '</li>';
            }
            stringToAdd = stringToAdd + '</ul>';
            stringToDisplay = stringToDisplay.replace(new RegExp('%REPEATING_APPT_DATE_LIST%', 'g'), stringToAdd);
        } else if (recurringAppt.timeSlotList && recurringAppt.timeSlotList.length > 0) {
            if (stringToDisplay.indexOf("%APPT_DATE_LIST%") !== -1) {
                let stringToAdd = '';
                for(let i = 0, x = recurringAppt.timeSlotList.length; i < x; i++){
                    stringToAdd = this.getStringFromRepeatingAppointment(recurringAppt, recurringAppt.timeSlotList[i], stringToAdd, preference, x);
                }

                stringToDisplay = stringToDisplay.replace(new RegExp('%APPT_DATE_LIST%', 'g'), stringToAdd);
            } else {
                for (let i = 0; i < tagList.length; i++) {
                    if (stringToDisplay.indexOf(tagList[i]) !== -1) {
                        let stringToAdd = '';
                        for (let i = 0, x = recurringAppt.timeSlotList.length; i < x; i++) {
                            stringToAdd = stringToAdd + '<li>' + this.getDisplayStringFromStringDate(recurringAppt.timeSlotList[i]) + '</li>';
                        }
                        stringToDisplay = stringToDisplay.replace(new RegExp(tagList[i], 'g'), stringToAdd);
                    }
                }
            }
        //there's no time slots so it's likely a course
        } else if (stringToDisplay.indexOf("%APPT_DATE_LIST%") !== -1) {
            let stringToAdd = this.getDisplayStringFromStringDate(recurringAppt);
            stringToDisplay = stringToDisplay.replace(new RegExp('%APPT_DATE_LIST%', 'g'), stringToAdd);
        }
        return stringToDisplay;
    }

    convertClientFormsList(stringPropertyToConvert: string, client: Client, preference: preference){
        let stringToDisplay = this.assignClientValuesToTags(stringPropertyToConvert, client.fields, client);
        if(stringToDisplay.indexOf('%FORM_LINK_LIST%') !== -1 && client.formList !== undefined && client.formList !== null && client.formList.length > 0){
            const labelMapString = "<a href='%FORM_URL%' target='_blank'>" + preference.labelMap.disclaimerFormLink + "</a>";
            let stringToAdd = '';
            // <li><a href='%FORM_URL%' target='_blank'>%FORM_NAME%</a></li>
            for(let i = 0, x = client.formList.length; i < x; i++){
                stringToAdd = stringToAdd + '<li>' + this.assignObjectToTags(client.formList[i], labelMapString, this.clientFormListObjTags) + '</li>';
            }
            stringToDisplay = stringToDisplay.replace(new RegExp('%FORM_LINK_LIST%', 'g'), stringToAdd);
        }
        return stringToDisplay;
    }

    sortWaitListDates(waitListDateList: WaitListDate[]){
        return waitListDateList.sort((a,b) => (a.date < b.date) ? -1 : 1);
    }

    sortWaitListDateTimes(waitListDateTimeList: WaitListDateTime[]){
        return waitListDateTimeList.sort((a,b) => (a.startTime < b.startTime) ? -1 : 1);
    }

    getStringFromRepeatingAppointment = function(recurringAppt: RecurringAppointment, timeslot: SelectedDateTimeObj, stringToAdd: string, preference: preference, waitlistLength: number){
        let newAppt = new Appointment();

        newAppt.startDateTimeUTC = timeslot.startDateTimeUTC;
        newAppt.endDateTimeUTC = timeslot.endDateTimeUTC;
        newAppt.clientStartTimeDate = timeslot.startDateTimeUTC;
        newAppt.clientEndTimeDate = timeslot.endDateTimeUTC;
        newAppt.clientStartDate = this.datePipe.transform(timeslot.clientStartDate, 'yyyy-MM-dd', 'UTC', this.locale);
        newAppt.clientEndDate = this.datePipe.transform(timeslot.clientEndDate, 'yyyy-MM-dd', 'UTC', this.locale);
        newAppt.clientStartTime = timeslot.clientStartTimeForDisplay as number || timeslot.startTime;
        newAppt.clientEndTime = timeslot.clientEndTimeForDisplay as number || timeslot.endTime;
        newAppt.location = recurringAppt.location;
        newAppt.staff = recurringAppt.staff;
        newAppt.reason = recurringAppt.reason;
        newAppt.client = recurringAppt.client;
        newAppt.fields = recurringAppt.fields;
        newAppt.startDate = this.datePipe.transform(timeslot.clientStartDate, 'yyyy-MM-dd', 'UTC', this.locale);
        newAppt.endDate = this.datePipe.transform(timeslot.clientEndTimeDate, 'yyyy-MM-dd', 'UTC', this.locale);
        newAppt.startTime = timeslot.clientStartTimeForDisplay as number || timeslot.startTime;
        newAppt.endTime = timeslot.clientEndTimeForDisplay as number || timeslot.endTime;

        stringToAdd = stringToAdd + "<li>" + this.convertApptPropertiesToTags('appointmentDateTimeList', preference, newAppt) + "</li>";

        return stringToAdd;
    };

    getStringFromIndyWaitListObjs = function(waitListObj: WaitList, stringToAdd: string, preference: preference, waitlistLength: number){
        waitListObj.waitListDateList = this.sortWaitListDates(waitListObj.waitListDateList);
        if (waitListObj.reason && waitListObj.reason.reasonType === ReasonTypes.COURSE) {
            for (let i = 0, x = waitListObj.waitListDateList.length; i < x; i++) {
                let waitListAppt = new Appointment();
                waitListAppt.status = waitListObj.status;
                waitListAppt.subStatus = waitListObj.status;
                waitListAppt.clientStartDate = this.datePipe.transform(waitListObj.waitListDateList[i].date, 'yyyy-MM-dd', 'UTC', this.locale);
                waitListAppt.clientEndDate = this.datePipe.transform(waitListObj.waitListDateList[i].date, 'yyyy-MM-dd', 'UTC', this.locale);
                waitListAppt.location = waitListObj.location;
                waitListAppt.staff = waitListObj.staff;
                waitListAppt.reason = waitListObj.reason;
                waitListAppt.client = waitListObj.client;
                waitListAppt.fields = waitListObj.fields;
                waitListAppt.startDate = this.datePipe.transform(waitListObj.waitListDateList[i].date, 'yyyy-MM-dd', 'UTC', this.locale);
                waitListAppt.endDate = this.datePipe.transform(waitListObj.waitListDateList[i].date, 'yyyy-MM-dd', 'UTC', this.locale);
                waitListAppt.waitListMode = true;
                if (waitListObj.waitListDateList[i].recurringScheduleId === null) {//waitlist is first available so show corresponding message
                    if (waitlistLength === 1)
                        stringToAdd = stringToAdd + this.convertApptPropertiesToTags('waitListDateListFirstAvail', preference, waitListAppt);
                    else
                        stringToAdd = stringToAdd + '<li>' + this.convertApptPropertiesToTags('waitListDateListFirstAvail', preference, waitListAppt) + '</li>';
                } else if (x === 1) {
                    stringToAdd = stringToAdd + this.convertApptPropertiesToTags('waitListDateListService', preference, waitListAppt);
                } else {
                    stringToAdd = stringToAdd + '<li>' + this.convertApptPropertiesToTags('waitListDateListService', preference, waitListAppt) + '</li>';
                }
            }
        } else {
            for (let i = 0, x = waitListObj.waitListDateList.length; i < x; i++) {
                waitListObj.waitListDateList[i].waitListDateTimeList = this.sortWaitListDateTimes(waitListObj.waitListDateList[i].waitListDateTimeList);
                for (let j = 0, y = waitListObj.waitListDateList[i].waitListDateTimeList.length; j < y; j++) {
                    let waitListAppt = new Appointment();
                    let isAllDay = false;
                    if (waitListObj.waitListDateList[i].waitListDateTimeList[j].startTime === 0 && waitListObj.waitListDateList[i].waitListDateTimeList[j].endTime === 2400)
                        isAllDay = true;
                    waitListAppt.status = waitListObj.status;
                    waitListAppt.subStatus = waitListObj.status;
                    waitListAppt.startDateTimeUTC = waitListObj.waitListDateList[i].waitListDateTimeList[j].startDateTimeUTC;
                    waitListAppt.endDateTimeUTC = waitListObj.waitListDateList[i].waitListDateTimeList[j].endDateTimeUTC;
                    waitListAppt.clientStartTimeDate = waitListObj.waitListDateList[i].waitListDateTimeList[j].startDateTimeUTC;
                    waitListAppt.clientEndTimeDate = waitListObj.waitListDateList[i].waitListDateTimeList[j].endDateTimeUTC;
                    waitListAppt.clientStartDate = this.datePipe.transform(waitListObj.waitListDateList[i].date, 'yyyy-MM-dd', 'UTC', this.locale);
                    waitListAppt.clientEndDate = this.datePipe.transform(waitListObj.waitListDateList[i].date, 'yyyy-MM-dd', 'UTC', this.locale);
                    waitListAppt.clientStartTime = waitListObj.waitListDateList[i].waitListDateTimeList[j].clientStartTimeForDisplay as number || waitListObj.waitListDateList[i].waitListDateTimeList[j].startTime;
                    waitListAppt.clientEndTime = waitListObj.waitListDateList[i].waitListDateTimeList[j].clientEndTimeForDisplay as number || waitListObj.waitListDateList[i].waitListDateTimeList[j].endTime;
                    waitListAppt.location = waitListObj.location;
                    waitListAppt.staff = waitListObj.staff;
                    waitListAppt.reason = waitListObj.reason;
                    waitListAppt.client = waitListObj.client;
                    waitListAppt.fields = waitListObj.fields;
                    waitListAppt.startDate = this.datePipe.transform(waitListObj.waitListDateList[i].date, 'yyyy-MM-dd', 'UTC', this.locale);
                    waitListAppt.endDate = this.datePipe.transform(waitListObj.waitListDateList[i].date, 'yyyy-MM-dd', 'UTC', this.locale);
                    waitListAppt.startTime = waitListObj.waitListDateList[i].waitListDateTimeList[j].clientStartTimeForDisplay as number || waitListObj.waitListDateList[i].waitListDateTimeList[j].startTime;
                    waitListAppt.endTime = waitListObj.waitListDateList[i].waitListDateTimeList[j].clientEndTimeForDisplay as number || waitListObj.waitListDateList[i].waitListDateTimeList[j].endTime;
                    waitListAppt.waitListMode = true;
                    if (waitListObj.waitListDateList[i].date === null) {//waitlist is first available so show corresponding message
                        if (waitlistLength === 1)
                            stringToAdd = stringToAdd + this.convertApptPropertiesToTags('waitListDateListFirstAvail', preference, waitListAppt);
                        else
                            stringToAdd = stringToAdd + '<li>' + this.convertApptPropertiesToTags('waitListDateListFirstAvail', preference, waitListAppt) + '</li>';
                    } else if (x === 1 && y === 1) {
                        if (isAllDay)
                            stringToAdd = stringToAdd + this.convertApptPropertiesToTags('waitListDateListAllDay', preference, waitListAppt);
                        else
                            stringToAdd = stringToAdd + this.convertApptPropertiesToTags('waitListDateListService', preference, waitListAppt);
                    } else {
                        if (isAllDay)
                            stringToAdd = stringToAdd + '<li>' + this.convertApptPropertiesToTags('waitListDateListAllDay', preference, waitListAppt) + '</li>';
                        else
                            stringToAdd = stringToAdd + '<li>' + this.convertApptPropertiesToTags('waitListDateListService', preference, waitListAppt) + '</li>';
                    }

                }
            }
        }
        return stringToAdd;
    };

    convertWaitListPropertiesToTags = function(stringPropertyToConvert: string, preference: preference, waitLists: WaitList[], waitListObj: WaitList){
        let stringToDisplay = this.getStringFromCommonObjectProperties(stringPropertyToConvert, preference, waitListObj.location, waitListObj.staff, waitListObj.reason, waitListObj.client, null, null);
        if(waitLists !== null && stringToDisplay.indexOf('%WAITLIST_DATE_LIST%') !== -1){
            let stringToAdd = '';
            if(waitLists.length > 1){
                stringToAdd = '<ul>';
            }
            for(let i = 0, x = waitLists.length; i < x; i++){
                stringToAdd = this.getStringFromIndyWaitListObjs(waitLists[i], stringToAdd, preference, x);
            }
            if(waitLists.length > 1){
                stringToAdd = stringToAdd + '</ul>';
            }


            stringToDisplay = stringToDisplay.replace(new RegExp('%WAITLIST_DATE_LIST%', 'g'), stringToAdd);
        }
        return stringToDisplay;
    };



    assignClientValuesToTags = function(stringWithTag: string, fields: CustomField[], client: Client){
        let stringToReturn = this.assignFieldsValuesToTags(stringWithTag, fields);
        if(stringToReturn === ''){
            stringToReturn = this.assignObjectToTags(client, stringWithTag, this.clientTagsArray);
        } else {
            stringToReturn = this.assignObjectToTags(client, stringToReturn, this.clientTagsArray);
        }

        //NOW REMOVE ANY TAGS THAT DIDN'T GET CONVERTED; NOT DOING THIS IN THE FIRST LOOP BECAUSE IT MAY BE FLAT ON TEH CLIENT OBJECT AND WILL BE TAKEN CARE OF WHEN PASSED TO ASSIGNOBJECTOTAGS FUNCTION
        stringToReturn = this.removeNonConvertedTags(stringToReturn, fields);
        return stringToReturn;
    }

    addValuesToColumnsArray(tag: string, tagsList: any[], propPrefix: string, columns: TableColumnDataModel[], labelMap: LabelMap){
        for(let j = 0, y = tagsList.length; j < y; j++){
            if(tagsList[j].tags.indexOf(tag) !== -1){
                let columnPropRef = propPrefix + tagsList[j].propName;
                let columnLabel = tagsList[j].propName;
                if(labelMap[columnPropRef] !== undefined)
                    columnLabel = labelMap[columnPropRef];
                else
                    columnLabel = this.titleCasePipe.transform(columnLabel);
                let filterType = null;
                let filterFormats: TableColumnDataFilterFormat[] = [];
                if (this.dateTimeTags.indexOf(tag) !== -1) { // LHB 05/21/2021 TT-7810 -- check if column needs a filter format applied
                    filterType = 'date';
                    for (let k = 0, z = this.dateTimeTagMap.length; k < z; k++)
                        if (this.dateTimeTagMap[k].tags.indexOf(tag) !== -1) {
                            for (let l = 0, a = this.dateTimeTagMap[k].filterFormats.length; l < a; l++) {
                                if (a === 2 && (this.dateTimeTagMap[k].filterFormats[l] === 'LT' || this.dateTimeTagMap[k].filterFormats[l] === 'LTS')) {
                                    const timeConcatenator: TableColumnDataFilterFormat = new TableColumnDataFilterFormat(' at ', true);
                                    filterFormats.push(timeConcatenator);
                                }
                                const filterFormat: TableColumnDataFilterFormat = new TableColumnDataFilterFormat(this.dateTimeTagMap[k].filterFormats[l], false, true);
                                filterFormats.push(filterFormat);
                            }
                        }
                }
                let columnObject = new TableColumnDataModel(columnPropRef, columnLabel);
                if (filterType !== null)
                    columnObject = new TableColumnDataModel(columnPropRef, columnLabel, filterType, filterFormats);
                columnObject.tag = tag;
                columns.push(columnObject);
            }
        }
        return columns;
    }
    getApptIntentListColumnValues = function(originalFieldList: string[], fields: CustomField[], labelMap: LabelMap, onMobileViewPort: boolean, bookingObjFieldTagsProp: string){
        let appointmentFieldList = _.cloneDeep(originalFieldList);
        let columns: TableColumnDataModel[] = [];
        for(let i = 0, x = appointmentFieldList.length; i < x; i++){
            columns = this.addValuesToColumnsArray(appointmentFieldList[i], this[bookingObjFieldTagsProp], '', columns, labelMap);
            for(let j = 0, y = fields.length; j < y; j++){
                if(fields[j].valueTag === appointmentFieldList[i]){
                    let columnPropRef = "field_" + fields[j].schedulerPreferenceFieldDefnId;
                    let columnObject = new TableColumnDataModel(columnPropRef, fields[j].label);
                    columnObject.tag = appointmentFieldList[i];
                    columns.push(columnObject);
                }
            }
        }
        for(let i = 0, x = columns.length; i < x; i++){
            let indexToReplace = appointmentFieldList.indexOf(columns[i].tag);
            appointmentFieldList[indexToReplace] = columns[i].name;
        }
        if(!onMobileViewPort){
            appointmentFieldList.push('actions');
        }
        return {columns: columns, appointmentFieldList: appointmentFieldList};
    };

    getApptListColumnValues = function(originalFieldList: string[], fields: CustomField[], labelMap: LabelMap, onMobileViewPort: boolean, bookingObjFieldTagsProp: string){


        let appointmentFieldList = _.cloneDeep(originalFieldList);
        let columns: TableColumnDataModel[] = [];
        for(let i = 0, x = appointmentFieldList.length; i < x; i++){
            columns = this.addValuesToColumnsArray(appointmentFieldList[i], this.locationFieldTags, 'location.', columns, labelMap);
            columns = this.addValuesToColumnsArray(appointmentFieldList[i], this.locationGroupFieldTags, 'locationGroup.', columns, labelMap);
            columns = this.addValuesToColumnsArray(appointmentFieldList[i], this.staffFieldTags, 'staff.', columns, labelMap);
            columns = this.addValuesToColumnsArray(appointmentFieldList[i], this.reasonFieldTags, 'reason.', columns, labelMap);
            columns = this.addValuesToColumnsArray(appointmentFieldList[i], this.apptIntentFieldTags, 'appointmentIntent.', columns, labelMap);
            columns = this.addValuesToColumnsArray(appointmentFieldList[i], this[bookingObjFieldTagsProp], '', columns, labelMap);
            for(let j = 0, y = fields.length; j < y; j++){
                if(fields[j].valueTag === appointmentFieldList[i]){
                    let columnPropRef = "field_" + fields[j].schedulerPreferenceFieldDefnId;
                    let columnObject = new TableColumnDataModel(columnPropRef, fields[j].label);
                    columnObject.tag = appointmentFieldList[i];
                    columns.push(columnObject);
                }
            }
        }
        for(let i = 0, x = columns.length; i < x; i++){
            let indexToReplace = appointmentFieldList.indexOf(columns[i].tag);
            appointmentFieldList[indexToReplace] = columns[i].name;
        }
        if(!onMobileViewPort){
            appointmentFieldList.push('actions');
        }
        return {columns: columns, appointmentFieldList: appointmentFieldList};
    };



    getInvalidFormFieldErrorMsg(fields: any[], fieldsPropId: string, formGroup: FormGroup, preference: preference, labelMapErrorMsgProp: string){
        // LHB 08/08/2020 TT-6867 show error pop up when fields are invalid
        let invalidFieldsString = '<ul>';
        let invalidFieldCount = 0;
        for(let prop in formGroup.controls){
            if(formGroup.controls[prop].invalid) {
                invalidFieldCount++;
                for(let i = 0, x = fields.length; i < x; i++){
                    if(prop === fields[i][fieldsPropId].toString())
                        invalidFieldsString = invalidFieldsString + '<li>' + fields[i].label + '</li>';
                }
            }
        }
        invalidFieldsString = invalidFieldsString + '</ul>';
        let errorMessage = preference.labelMap[labelMapErrorMsgProp];
        if(errorMessage.indexOf('%INVALID_FIELD_COUNT%')!== -1){
            errorMessage = errorMessage.replace(new RegExp("%INVALID_FIELD_COUNT%", 'g'), invalidFieldCount.toString());
        }
        return errorMessage + invalidFieldsString;
    }

    getDisplayStringFromStringDate(timeSlot: any) {
        let startDate = timeSlot.clientStartDate;
        let startTime = timeSlot.startTime;
        let startTimeString: String;
        let amOrPm: String;
        if (typeof startDate !== 'string') {
            startDate = startDate.toDateString();
        }
        let month = startDate.substring(5, 7);
        switch(month) {
            case '01':
                month = 'Jan';
                break;
            case '02':
                month = 'Feb';
                break;
            case '03':
                month = 'Mar';
                break;
            case '04':
                month = 'Apr';
                break;
            case '05':
                month = 'May';
                break;
            case '06':
                month = 'Jun';
                break;
            case '07':
                month = 'Jul';
                break;
            case '08':
                month = 'Aug';
                break;
            case '09':
                month = 'Sep';
                break;
            case '10':
                month = 'Oct';
                break;
            case '11':
                month = 'Nov';
                break;
            case '12':
                month = 'Dec';
                break;
        }
        let day = startDate.substring(8, 10);

        if (day.charAt(0) === '0') {
            day = day.substring(1);
        }
        let year = startDate.substring(0, 4);
        startDate = startDate.substring(5, 7) + '/' + startDate.substring(8, 10) + '/' + startDate.substring(0, 4);

        if (startTime > 1259) {
            startTime -= 1200;
            amOrPm = 'PM';
        } else {
            amOrPm = 'AM';
            if (startTime >= 1200) {
                amOrPm = 'PM';
            }
            if (startTime < 100) {
                startTime += 1200;
            }

        }
        if (startTime < 1000) {
            startTimeString = startTime.toString();
            startTimeString = startTimeString.substring(0, 1) + ':' + startTimeString.substring(1);
        } else {
            startTimeString = startTime.toString();
            startTimeString = startTimeString.substring(0, 2) + ':' + startTimeString.substring(2);
        }
        return month + ' ' + day + ', ' + year + ' ' + startTimeString + ':00 ' + amOrPm;
        return startDate + ' at ' + startTimeString + ' ' + amOrPm;
    }

}
