import {ScreeningQuestion} from './ScreeningQuestion.model';
import {ConfigListMap} from '../app/common-classes/app-objects.model';

export enum ReasonTypes {
    SERVICE = 'SERVICE',
    CLASS = 'CLASS',
    COURSE = 'COURSE',
    PERSONAL = 'PERSONAL',
    ADDON = 'ADDON'
}

export class ReasonDuration {
    originalPrice: number;
    active: boolean;
    deposit: number;
    duration: number;
    durationName: string;
    internalName: string;
    price: number;
    staffRateLevel: null;
    friendlyDuration: string;
    selected?: boolean;

    constructor() {
    }
}

export class RepeatingApptOption {
    active: boolean;
    deposit: number;
    displayName: string;
    everyXMonths: number;
    everyXWeeks: number;
    internalName: string;
    numberOfAppointments: number;
    price: number;
}

export class Reason {
    selectedAddOns: Reason[];
    addOnReasonList: Reason[];
    emailInstructions: string;
    emailInstructions2: string;
    allowRepeating: number;
    repeatingApptOptionList: RepeatingApptOption[];
    selectedRepeatingApptOption: RepeatingApptOption;
    needToSelectRepeatingOption: boolean;
    displayName: string;
    reasonId: number;
    reasonType: ReasonTypes;
    reasonDesc: string;
    reasonDetail: string;
    price: number;
    imageUrl: string;
    visitMinutes: number;
    businessId: number;
    friendlyDuration: string;
    durationList: ReasonDuration[];
    numberSessions: number;
    multiDuration: boolean;
    selectedDuration: ReasonDuration;
    needToSelectDuration: boolean;
    requireStaffConfirmation: boolean;
    requirePreAuthPayment: boolean;
    bookMultipleAppts: boolean;
    requirePayment: boolean;
    allowWaitList: boolean;
    selected?: boolean;
    screeningQuestion?: ScreeningQuestion;
    showExtendedHeight?: boolean;
    textFloatHeight?: number;
    originalPrice?: number;
    apptDayMax?: number;
    apptHrMin?: number;
    taxable?: boolean;
    depositAmount?: number;
    clientDuration?: number;
    requireApptIntent: boolean;
    internalName: string;

    constructor() {
    }
    createNoWaitListAbleReason(configList: ConfigListMap) {
        this.reasonId = -1;
        this.displayName = 'No ' + configList.serviceLabelPlural + ' or ' + configList.classLabelPlural + ' allow wait listing for the selected ' + configList.locationLabel + ' and ' + configList.staffLabel;
        return this;
    }
}
